import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl } from '../../../constants'
import { checkAuthorization } from '../../../helpers';
import { message } from 'antd'

export const getAddEmployeeDetail = createAsyncThunk('getEmployeeStatistics',
    async (object, { dispatch, getState }) => {

        const {userToken}= checkAuthorization()
        dispatch(gettingAddEmployeeDetail())
        var myHeaders = new Headers();
        myHeaders.append("Authorization", userToken)
        myHeaders.append("Content-Type", "application/json")
        myHeaders.append("pageNO", object?.pageNo)
        myHeaders.append("recordsPerPage", object?.pageSize)
        var requestOptions = {
            method: 'Post',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({title: object?.title, order: object?.order, name:object?.name})
        };
        fetch(domainUrl + `/viewAllEmployees`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result?.success) {
                    dispatch(addemployeeDetailResponse(result?.data))
                    dispatch(setPages({pageNo: object?.pageNo, pageSize: object?.pageSize, totalRecords: result?.totalRecords}))
                }
                else 
                    throw result?.message
            })
            .catch(error => { 
                dispatch(addemployeeDetailClear())
                message.error(error) 
            })
    }
)

const AddEmployeeSlice = createSlice({
    name: 'employee',
    initialState: {
        data: null,
        loading: false,
        addemployeeDetail: null,
        loadingAddEmployeeDetail: false,
        pagination:{
            pageNo:1,
            pageSize: 10,
            totalRecords:0,
        },
        filters: {
            title: '',
            order: 1,
        },
    },
    reducers: {
        getPatientStatistics: state => {
            state.loading=true
            state.data=null
        },
        statisticsResponse: (state, action)=>{
            state.data= action.payload
            state.loading= false
        },
        statisticsClear: (state)=>{
            state.data= null
            state.loading= false
        },
        gettingAddEmployeeDetail: state => {
            state.loadingAddEmployeeDetail=true
            state.addemployeeDetail=null
        },
        addemployeeDetailResponse: (state, action)=>{
            state.addemployeeDetail= action.payload
            state.loadingAddEmployeeDetail= false
        },
        addemployeeDetailClear: (state)=>{
            state.addemployeeDetail= null
            state.loadingAddEmployeeDetail= false
        },
        changeAddemployeeDetailFilters: (state, action) => {
            state.filters = action.payload
        },
        setPages:(state, action)=>{
            state.pagination= action.payload
        },
    },
})
export const { 
    gettingaddboxStatistics, statisticsResponse, statisticsClear,
    gettingAddEmployeeDetail, addemployeeDetailResponse, addemployeeDetailClear,
    changeAddemployeeDetailFilters, setPages
} = AddEmployeeSlice.actions;
export default AddEmployeeSlice.reducer;