import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Card, DatePicker, Drawer, Button, Select } from 'antd';
import { HospiceInput } from '../../input';
import TimeField from '../TimeField';
import { apiCalls } from '../../../shared/Apis';
import { checkAuthorization, domainUrl } from '../../../shared';
import moment from 'moment';

const { Option } = Select;

const AddapointmentDrawer = ({ visible, onClose, viewonly, getAddAppointmentDetail }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [patienttype, setPatientType] = useState([]);
    const [employeetype, setEmployeeType] = useState([]);
    const [datearray, setDateArray] = useState([]);

    useEffect(() => {
        if (visible && viewonly) {
            ViewAppointmentOnly();
        } else if (visible) {
            getPatientType();
            getEmployeeType();
        } else {
            form.resetFields();
        }
    }, [visible]);

    const CreateApiAppointment = async (formData) => {
        setLoading(true);
        try {
            const result = await apiCalls.appointmentApi.CreateApiAppointment({
                ...formData,
                patientID: patienttype?.find(patient => patient?.firstName === formData?.patientID)?.id,
                employeeID: employeetype?.find(employee => employee?.firstName === formData?.employeeID)?.id,
            });
            setLoading(false);
            getAddAppointmentDetail();
            if (result) onClose();
        } catch (error) {
            console.error("Error updating data:", error);
        }
    };

    const onFinish = async () => {
        try {
            let formData = await form.validateFields();
            formData = {
                ...formData,
                dates: formData?.dates?.map((timedates, index) => {
                    timedates = {
                        ...timedates,
                        datesincre: index + 1,
                        fromTime: moment(timedates?.fromTime).format('LT'),
                        toTime: moment(timedates?.toTime).format('LT'),
                        date: moment(timedates.date).format('YYYY-MM-DD'),
                    };
                    return timedates;
                }),
            };
            CreateApiAppointment(formData);
        } catch (error) {
            console.error("Form validation error:", error);
        }
    };

    const getPatientType = async () => {
        let result = await apiCalls?.appointmentApi?.getPatientType();
        if (result) setPatientType(result);
    };

    const getEmployeeType = async () => {
        let result = await apiCalls?.appointmentApi?.getEmployeeType();
        if (result) setEmployeeType(result);
    };

    const ViewAppointmentOnly = () => {
        const { userToken } = checkAuthorization();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', userToken);
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(domainUrl + `/viewAppointment/${viewonly?.id}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result?.success) {
                    result = result.data;
                    form.setFieldsValue({
                        ...result,
                        patientID: result?.patient?.firstName,
                        employeeID: result?.employee?.firstName
                    });
                    setDateArray(result?.appointment_dates);
                } else {
                    throw new Error('Failed to fetch appointment data');
                }
            })
            .catch(error => {
                alert(error.message);
            });
    };

    return (
        <div>
            <Drawer
                title={viewonly ? `View only` : 'Add appointment'}
                onClose={onClose}
                open={visible}
                width={1000}
                footer={
                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 'small' }}>
                        <Button onClick={onClose}>Cancel</Button>
                        {!viewonly && (
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={loading}
                                onClick={() => form.submit()}
                            >
                                Save
                            </Button>
                        )}
                    </div>
                }
            >
                <Form
                    form={form}
                    layout="vertical"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                >
                    <Card className='shadow-in border0 radius-12'>
                        <Row gutter={16}>
                            <Col xs={24} sm={24} md={24} lg={12}>
                                <Form.Item
                                    label="Patient name"
                                    name="patientID"
                                    rules={[{ required: !viewonly, message: 'Please choose patient name' }]}
                                    className='disablecolor'
                                >
                                    <Select
                                        placeholder="Choose patient name"
                                        disabled={viewonly}
                                    >
                                        {patienttype.map(patient => (
                                            <Option key={patient.id} value={patient.firstName}>
                                                {patient.firstName + ' ' + patient.lastName}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12}>
                                <HospiceInput
                                    name='visitType'
                                    label='Visit type'
                                    placeholder='Enter visit type'
                                    required={!viewonly}
                                    message='Please enter visit type'
                                    disabled={viewonly}
                                />
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12}>
                                <Form.Item
                                    label="Employee name"
                                    name="employeeID"
                                    rules={[{ required: !viewonly, message: 'Please choose employee name' }]}
                                    className='disablecolor'
                                >
                                    <Select
                                        placeholder="Choose employee name"
                                        disabled={viewonly}
                                    >
                                        {employeetype.map(employee => (
                                            <Option key={employee.id} value={employee.firstName}>
                                                {employee.firstName + ' ' + employee.lastName}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12}>
                                <HospiceInput
                                    name='location'
                                    label='Location'
                                    placeholder='Enter location'
                                    required={!viewonly}
                                    message='Please enter location'
                                    disabled={viewonly}
                                />
                            </Col>
                            {
                                viewonly ?
                                    datearray.map(data => (
                                        <>
                                            <Col xs={24} sm={24} md={24} lg={8} key={`date-${data.id}`}>
                                                <HospiceInput
                                                    label='Date'
                                                    value={moment(data.date).format('MMMM Do YYYY')}
                                                    disabled={viewonly}
                                                />
                                            </Col>
                                            <Col xs={24} sm={24} md={24} lg={8} key={`fromTime-${data.id}`}>
                                                <HospiceInput
                                                    label='From time'
                                                    value={data.fromTime}
                                                    disabled={viewonly}
                                                />
                                            </Col>
                                            <Col xs={24} sm={24} md={24} lg={8} key={`toTime-${data.id}`}>
                                                <HospiceInput
                                                    label='To time'
                                                    value={data.toTime}
                                                    disabled={viewonly}
                                                />
                                            </Col>
                                        </>
                                    ))
                                    :
                                    <Col span={24}>
                                        <TimeField {...{form}} visible={visible} />
                                    </Col>
                            }
                            <Col span={24}>
                                <HospiceInput
                                    textArea
                                    name='instruction'
                                    label='Instruction'
                                    placeholder='Enter instruction'
                                    required={!viewonly}
                                    message='Please enter instruction'
                                    disabled={viewonly}
                                />
                            </Col>
                        </Row>
                    </Card>
                </Form>
            </Drawer>
        </div>
    );
};

export { AddapointmentDrawer };
