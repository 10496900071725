import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl } from '../../../constants'
import { checkAuthorization } from '../../../helpers';
import { message } from 'antd'

export const getVisitDetail = createAsyncThunk('getVisitStatistics',
    async (object, { dispatch, getState }) => {

        const {userToken}= checkAuthorization()
        dispatch(gettingVisitDetail())
        var myHeaders = new Headers();
        myHeaders.append("Authorization", userToken)
        myHeaders.append("Content-Type", "application/json")
        myHeaders.append("pageNO", object?.pageNo)
        myHeaders.append("recordsPerPage", object?.pageSize)
        var requestOptions = {
            method: 'Post',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({id:object.id, order: object?.order, name:object?.name})
        };
        fetch(domainUrl + `/viewVisitsByPatient`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result?.success) {
                    dispatch(visitshowDetailResponse(result?.data))
                    dispatch(setPages({pageNo: object?.pageNo, pageSize: object?.pageSize, totalRecords: result?.totalRecords}))
                }
                else 
                    throw result?.message
            })
            .catch(error => { 
                dispatch(visitviewDetailClear())
                message.error(error) 
            })
    }
)

const VisitViewSlice = createSlice({
    name: 'visitapi',
    initialState: {
        data: null,
        loading: false,
        visitviewDetail: null,
        loadingVisitviewDetail: false,
        pagination:{
            pageNo:1,
            pageSize: 10,
            totalRecords:0,
        },
        filters: {
            id:'',
            name: '',
            order: 1,
        },
    },
    reducers: {
        getPatientStatistics: state => {
            state.loading=true
            state.data=null
        },
        statisticsResponse: (state, action)=>{
            state.data= action.payload
            state.loading= false
        },
        statisticsClear: (state)=>{
            state.data= null
            state.loading= false
        },
        gettingVisitDetail: state => {
            state.loadingVisitviewDetail=true
            state.visitviewDetail=null
        },
        visitshowDetailResponse: (state, action)=>{
            state.visitviewDetail= action.payload
            state.loadingVisitviewDetail= false
        },
        visitviewDetailClear: (state)=>{
            state.visitviewDetail= null
            state.loadingVisitviewDetail= false
        },
        changeVisitViewDetailFilters: (state, action) => {
            state.filters = action.payload
        },
        setPages:(state, action)=>{
            state.pagination= action.payload
        },
    },
})
export const { 
    gettingaddboxStatistics, statisticsResponse, statisticsClear,
    gettingVisitDetail, visitshowDetailResponse, visitviewDetailClear,
    changeVisitViewDetailFilters, setPages
} = VisitViewSlice.actions;
export default VisitViewSlice.reducer;