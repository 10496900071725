import React, { useEffect, useState } from 'react'
import { Space, Row, Col, Form, Typography, Card, Drawer, Avatar, message } from 'antd'
import {  HospiceInput, ModuleTopSmhead, ActionButton } from '..';
import { CloseOutlined, DownloadOutlined } from '@ant-design/icons';
import { checkAuthorization, domainUrl } from '../../shared';
import { apiCalls } from '../../shared/Apis';

const { Text } = Typography

const ViewEmployeeDrawer = ({visible, onClose,viewid}) => {

    const [form] = Form.useForm()
    const [ singledata, setSingleData ] = useState(null) 
    const [ idcarddownload, setIdCardDownload ] = useState(null)

    useEffect(()=>{
        if(viewid){
            SingleViewEmp()
        }
    },[viewid])
   
    const SingleViewEmp=()=>{
        const {userToken} = checkAuthorization()
        var myHeaders = new Headers();
        myHeaders.append("Content-Type","application/json")
        myHeaders.append('Authorization', userToken);
        var requestOptions = {
          method:'GET',
          headers: myHeaders,
          redirect:'follow'
        }
        return(
            fetch(domainUrl + `/viewEmployee/${viewid}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result?.data)
                if (result?.success) {
                    setSingleData(result?.data)
                    form.setFieldsValue(result?.data)                   
                } 
                else 
                    throw result?.message
            })
            .catch(error => {
                message.error(error.message || 'Something went wrong');
            })
        )
      }
    
    

      const downloadfile = (path,filename)=> {
        const url = document.createElement("a");
        url.href = path;
        url.setAttribute('download',filename);
        document.body.appendChild(url)
        url.click();
        document.body.removeChild(url)
    }
    

  return (
    <div>
        <Drawer
            onClose={onClose}
            open={visible}
            width={1000}
            footer={false}
            closeIcon={false}
        >
            <Form
                    form={form}
                    layout="vertical"
                    initialValues={{
                        remember: true,
                    }}
                    // onFinish={onFinish}
                >
                    <Space direction='vertical' size={15} className='w-100'>
                        <div className='w-100 space-between'>
                            <Space size={20}>
                                <Avatar
                                    size={50}
                                    icon={<img src={singledata?.profileImage} width={120} />}
                                />
                                <div>
                                    <Typography.Title level={5} className='m-0'>
                                        {singledata?.firstName}
                                    </Typography.Title>
                                    <Typography.Text className='grayish fs-12'>
                                        Doctor
                                    </Typography.Text>
                                </div>
                            </Space>
                            <ActionButton
                                icon={<CloseOutlined />}
                                onClick={onClose}
                                type='ghost'
                            />
                        </div>
                        <Card className='shadow-in border0 radius-12'> 
                            <Row gutter={16}>
                                <Col span={24}>
                                    <div className='mb-3'>
                                        <ModuleTopSmhead
                                            name='Personal Details'
                                        />
                                    </div>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12}>
                                    <HospiceInput
                                        name='firstName'
                                        label='First name'
                                        value={form.getFieldValue("firstName") || ''} 
                                        disabled
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12}>
                                    <HospiceInput
                                        name='lastName'
                                        label='Last name'
                                        value={form.getFieldValue("lastName") || ''} 
                                        disabled
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12}>
                                    <HospiceInput
                                        name='email'
                                        label='Email address'
                                        value={form.getFieldValue("email") || ''} 
                                        disabled
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12}>
                                    <HospiceInput
                                        name='password'
                                        type='password'
                                        label='Password'
                                        value={form.getFieldValue("password") || ''}
                                        disabled 
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12}>
                                    <HospiceInput
                                        name='age'
                                        label='Age'
                                        value={form.getFieldValue("age") || ''} 
                                        disabled
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12}>
                                    <HospiceInput
                                        name='phoneNumber'
                                        label='Phone number'
                                        value={form.getFieldValue("phoneNumber") || ''} 
                                        disabled
                                    />
                                </Col>
                                <Col span={24}>
                                    <HospiceInput
                                        textArea
                                        name='education'
                                        label='Education'
                                        value={form.getFieldValue("education") || ''}
                                        disabled
                                    />
                                </Col>
                            </Row>
                        </Card>
                        <Card className='shadow-in border0 radius-12'> 
                            <Row gutter={16}>
                                <Col span={24}>
                                    <div className='mb-3'>
                                        <ModuleTopSmhead
                                            name='Educational Details'
                                        />
                                    </div>
                                </Col>
                                <Col span={24}>
                                    <HospiceInput
                                        name='specialist'
                                        label='Specialist'
                                        value={form.getFieldValue("specialist") || ''} 
                                        disabled
                                    />
                                </Col>
                                <Col span={24}>
                                    <HospiceInput
                                        textArea
                                        name='education'
                                        label='Education'
                                        value={form.getFieldValue("education") || ''}
                                        disabled
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={12}>
                                    <Space direction='vertical' size={10} className='w-100'>
                                        {
                                            singledata?.education_docs?.map((data,index)=>
                                                <div className='viewDownload space-between-align' key={index}>
                                                    <Typography.Text className='gray-text fs-12'>
                                                        {data?.name}
                                                    </Typography.Text>
                                                    <ActionButton
                                                        title='Download'
                                                        icon={<DownloadOutlined />}
                                                        type='ghost'
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            downloadfile(data?.path, data?.name);
                                                        }}
                                                    />
                                                </div>
                                            )
                                        }
                                    </Space>
                                </Col>
                            </Row>
                        </Card>
                        <Card className='shadow-in border0 radius-12'> 
                            <Row gutter={16}>
                                <Col span={24}>
                                    <div className='mb-3'>
                                        <ModuleTopSmhead
                                            name='Identity Card'
                                        />
                                    </div>
                                </Col>
                                <Col xs={24} sm={24} md={12}>
                                    <Space direction='vertical' size={10} className='w-100'>
                                        {
                                            singledata?.id_cards?.map((data,index)=>
                                                <div className='viewDownload space-between-align' key={index}>
                                                    <Typography.Text className='gray-text fs-12'>
                                                        {data?.name}
                                                    </Typography.Text>
                                                    <a href={data?.path} download={data?.name}>
                                                        <ActionButton
                                                            title='Download'
                                                            icon={<DownloadOutlined />}
                                                            type='ghost'
                                                        />
                                                    </a>
                                                </div>
                                            )
                                        }
                                    </Space>
                                </Col>
                            </Row>
                        </Card>
                    </Space>
                </Form>
        </Drawer>
    </div>
  )
}

export {ViewEmployeeDrawer}