import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl } from '../../../constants'
import { checkAuthorization } from '../../../helpers';
import { message } from 'antd'

export const getAddAppointmentDetail = createAsyncThunk('getAppointmentStatistics',
    async (object, { dispatch, getState }) => {

        const {userToken}= checkAuthorization()
        dispatch(gettingAddAppointmentDetail())
        var myHeaders = new Headers();
        myHeaders.append("Authorization", userToken)
        myHeaders.append("Content-Type", "application/json")
        myHeaders.append("pageNO", object?.pageNo)
        myHeaders.append("recordsPerPage", object?.pageSize)
        var requestOptions = {
            method: 'Post',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({title: object?.title, order: object?.order, name:object?.name})
        };
        fetch(domainUrl + `/viewAllAppointments`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result?.success) {
                    dispatch(addappointmentDetailResponse(result?.data))
                    dispatch(setPages({pageNo: object?.pageNo, pageSize: object?.pageSize, totalRecords: result?.totalRecords}))
                }
                else 
                    throw result?.message
            })
            .catch(error => { 
                dispatch(addappointmentDetailClear())
                message.error(error) 
            })
    }
)

const AddAppointmentSlice = createSlice({
    name: 'appointment',
    initialState: {
        data: null,
        loading: false,
        addappointmentDetail: null,
        loadingAddAppointmentDetail: false,
        pagination:{
            pageNo:1,
            pageSize: 10,
            totalRecords:0,
        },
        filters: {
            title: '',
            order: 1,
        },
    },
    reducers: {
        getPatientStatistics: state => {
            state.loading=true
            state.data=null
        },
        statisticsResponse: (state, action)=>{
            state.data= action.payload
            state.loading= false
        },
        statisticsClear: (state)=>{
            state.data= null
            state.loading= false
        },
        gettingAddAppointmentDetail: state => {
            state.loadingAddAppointmentDetail=true
            state.addappointmentDetail=null
        },
        addappointmentDetailResponse: (state, action)=>{
            state.addappointmentDetail= action.payload
            state.loadingAddAppointmentDetail= false
        },
        addappointmentDetailClear: (state)=>{
            state.addappointmentDetail= null
            state.loadingAddAppointmentDetail= false
        },
        changeAddappointmentDetailFilters: (state, action) => {
            state.filters = action.payload
        },
        setPages:(state, action)=>{
            state.pagination= action.payload
        },
    },
})
export const { 
    gettingaddboxStatistics, statisticsResponse, statisticsClear,
    gettingAddAppointmentDetail, addappointmentDetailResponse, addappointmentDetailClear,
    changeAddappointmentDetailFilters, setPages
} = AddAppointmentSlice.actions;
export default AddAppointmentSlice.reducer;