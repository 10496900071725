import { Form, Image, Button, Space, Row, Col, Typography, Checkbox, Flex} from "antd"
import { HospiceInput } from '../../components'
import "./index.css"
import { useState } from "react"
import { Link } from "react-router-dom"
import { actionsApi } from "../../shared"
import { useDispatch, useSelector } from "react-redux"
const {Title}= Typography

const Login = () => {

    const appDispatcher = useDispatch()
    const [form] = Form.useForm()
    const { loading } = useSelector(state => state?.login)
    const [check, setCheck] = useState()

    const onChange = (e) =>{
       setCheck(e.target.checked)
    }

    const login = () => {
        appDispatcher(actionsApi?.login(form.getFieldsValue(['email', 'password'])))
    }
    return (
        <div 
            className='login-form-cover'
            >
            <Flex justify="center">
                <Row className="login-form border-grey" justify={"center"}>
                    <Col xs={24} sm={24} md={14}>
                        <Flex vertical align="center" gap={'large'} justify="center" className="height-100">
                            <Flex vertical align="center" justify="center">
                                <Image
                                    src={"./assets/logo.png"}
                                    alt='Store App Admin Panel'
                                    preview= {false}
                                    width={'100px'}
                                />
                                <Title 
                                    level={5} 
                                    className="text-center brand-color fs-13"
                                    >
                                    Welcome back! Login to your account
                                </Title>
                            </Flex>
                            <Form
                                form={form}
                                layout="vertical"
                                initialValues={{
                                    remember: true,
                                }}
                                style={{ width: '100%' }}
                                onFinish={login}
                            >
                                <Row>
                                    <Col span={24}>
                                        <HospiceInput
                                            autoFocus
                                            name="email"
                                            label="Email Address"
                                            size='large'
                                            required
                                            message='please enter your email address'
                                            placeholder='Enter your email address'
                                            value={form.getFieldValue('email') || ''}
                                        />
                                    </Col>
                                    <Col span={24}>
                                        <HospiceInput
                                            name="password"
                                            type='password'
                                            label="Password"
                                            size='large'
                                            required
                                            message='please enter password'
                                            placeholder='Enter your password here'
                                            value={form.getFieldValue('password') || ''}
                                        />
                                    </Col>
                                    <Col span={24}>
                                        <Flex justify="space-between">
                                            <Checkbox onChange={onChange} className="mb-3">Remember me</Checkbox>
                                            <Link to={'/sendemail'}>
                                                <Typography.Text className="brand-color">
                                                    Forgot password?
                                                </Typography.Text>
                                            </Link>
                                        </Flex>
                                    </Col>
                                    <Col span={24}>
                                        <Button 
                                            type="primary"
                                            htmlType="submit" 
                                            loading={loading}
                                            >
                                            Login
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Flex>
                    </Col>
                    {/* <Col md={12} sm={0} xs={0}
                        className="bg-gray2 flex-col-align-end"
                        >
                        <Space direction="vertical" className="px-4" size={0} align="center" style={{justifyContent:"center",height:"100%"}}>
                            <Image src="/assets/login-right.png" className="w-100" preview={false} />
                            <Title 
                                level={5} 
                                className="text-center gray-color"
                                >
                                Version 1.1.6 Beta Release
                            </Title>
                        </Space>
                    </Col> */}
                </Row>
            </Flex>
        </div>
    )
}
export {Login}