import React, { useState } from 'react'
import { Modal, Space, Typography, Button } from 'antd'
// import { apiCalls } from '../../shared/Apis';
import { DeleteOutlined } from '@ant-design/icons';
import { apiCalls } from '../../../shared/Apis';
const { Title, Text } = Typography
const AlertAppointModal = ({onClose, deleteappid,deletemodal,getAddAppointmentDetail}) => {
    
    const [ loading, setLoading ] = useState(false)

    const DeleteAppointmentApi = async() => {
        setLoading(true)
        try{
            const result = await apiCalls.appointmentApi.DeleteAppointmentApi(deleteappid?.id)
            setLoading(false)
            onClose();
            getAddAppointmentDetail();
        } catch (error){
            console.error("Error deleting data",error)
        }
    }
    
  return (
    <div>
        <Modal width={500} className='shadow-c modal'  
            open={deletemodal} 
            onOk={onClose} 
            onCancel={onClose} 
            centered 
            footer={null}
        >
            <Space className='w-100 py-2 text-center' align='center' size={10} direction='vertical'>
                <DeleteOutlined 
                    style={{fontSize: '40px'}} 
                    className='danger-color'
                />
                <Title level={4} className='my-0'>Alert</Title>
                <Text className='text-input text-center'>Are you sure you want to permanently delete this user? <strong>"{deleteappid ? deleteappid?.name : ''  }"</strong></Text>
                <Space style={{justifyContent:"end",marginTop:'15px'}} className="w-100">
                    <Button
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button  
                        block
                        type="primary"
                        loading={loading}
                        onClick={
                            DeleteAppointmentApi
                        }
                    >
                        Confirm
                    </Button>
                </Space>
            </Space>
        </Modal>
    </div>
  )
}

export {AlertAppointModal}