import React, { useState } from 'react'
import { AppointConsultComponent, CardComponent, BarChartComponent } from '../../components'
import { Space } from 'antd'
import { Patients } from '../Patients'

const Dashboard = () => {

  const [ removeadd, setRemoveAdd ] = useState(true)

  return (
    <Space direction='vertical' size={20} className='w-100'>
      <CardComponent />
      <BarChartComponent />
      <AppointConsultComponent />
      <Patients removeadd={removeadd} />
    </Space>
  )
}

export {Dashboard}