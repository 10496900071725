import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl } from '../../../constants'
import { checkAuthorization } from '../../../helpers';
import { message } from 'antd'

export const getVisitSingleDetail = createAsyncThunk('getVisitSingleStatistics',
    async (object, { dispatch, getState }) => {

        const {userToken}= checkAuthorization()
        dispatch(gettingVisitSingleDetail())
        var myHeaders = new Headers();
        myHeaders.append("Authorization", userToken)
        myHeaders.append("Content-Type", "application/json")
        myHeaders.append("pageNO", object?.pageNo)
        myHeaders.append("recordsPerPage", object?.pageSize)
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify()
        };
        fetch(domainUrl + `/viewSingleVisit/${object?.id}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result?.success) {
                    dispatch(visitsingleDetailResponse(result?.data))
                    console.log(result?.data)
                }
                else 
                    throw result?.message
            })
            .catch(error => { 
                dispatch(visitsingleDetailClear())
                message.error(error) 
            })
    }
)

const VisitSingleSlice = createSlice({
    name: 'visitapisingle',
    initialState: {
        data: null,
        loading: false,
        visitsingDetail: null,
        loadingVisitSingDetail: false
    },
    reducers: {
        getPatientStatistics: state => {
            state.loading=true
            state.data=null
        },
        statisticsResponse: (state, action)=>{
            state.data= action.payload
            state.loading= false
        },
        statisticsClear: (state)=>{
            state.data= null
            state.loading= false
        },
        gettingVisitSingleDetail: state => {
            state.loadingVisitSingDetail=true
            state.visitsingDetail=null
        },
        visitsingleDetailResponse: (state, action)=>{
            state.visitsingDetail= action.payload
            state.loadingVisitSingDetail= false
        },
        visitsingleDetailClear: (state)=>{
            state.visitsingDetail= null
            state.loadingVisitSingDetail= false
        }
    },
})
export const { 
    gettingaddboxStatistics, statisticsResponse, statisticsClear,
    gettingVisitSingleDetail, visitsingleDetailResponse, visitsingleDetailClear
} = VisitSingleSlice.actions;
export default VisitSingleSlice.reducer;