import { message } from "antd";
import { checkAuthorization } from "../../helpers";
import { domainUrl } from "../../constants";


const CreateApiAppointment = (formData) => {
    const {userToken} = checkAuthorization()
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append('Authorization', userToken);
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(formData),
        redirect: 'follow'
    };
    return (
        fetch(domainUrl + '/createAppointment', requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result?.success) {
                message.success(result?.message)
                return 1
            } else {
                message.error('Something went wrong');
                return 0
            }
        })
        .catch(error => {
            message.error(error.message || 'Something went wrong');
            return 0
        })
    )
};

const CreateRescheduleAppointment = (formData) => {
    const {userToken} = checkAuthorization()
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append('Authorization', userToken);
    
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(formData),
        redirect: 'follow'
    };
    return (
        fetch(domainUrl + `/rescheduleAppointment`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result?.success) {
                message.success(result?.message)
                return 1
            } else {
                message.error('Something went wrong');
                return 0
            }
        })
        .catch(error => {
            message.error(error.message || 'Something went wrong');
            return 0
        })
    )
};

const getPatientType= ()=>{
    const {userToken} = checkAuthorization()
    var myHeaders = new Headers()
    myHeaders.append('Authorization', userToken)
    return (
        fetch(domainUrl + "/selectPatient", {
            method: "GET",
            headers: myHeaders
        })
        .then(response => response.json())
        .then(result => {
            if (result?.success) 
                return result?.data
            else
                throw result?.message
        })
        .catch(error => {
            message.error(error)
            return 0
        })
    )
}

const getEmployeeType= ()=>{
  const {userToken} = checkAuthorization()
  var myHeaders = new Headers()
  myHeaders.append('Authorization', userToken)
  return (
      fetch(domainUrl + "/selectEmployee", {
          method: "GET",
          headers: myHeaders
      })
      .then(response => response.json())
      .then(result => {
          if (result?.success) 
              return result?.data
          else
              throw result?.message
      })
      .catch(error => {
          message.error(error)
          return 0
      })
  )
}


const DeleteAppointmentApi=(deleteappid)=>{
    const {userToken} = checkAuthorization()
    var myHeaders = new Headers();
    myHeaders.append("Content-Type","application/json")
    myHeaders.append('Authorization', userToken);
    var requestOptions = {
      method:'DELETE',
      headers: myHeaders,
      redirect:'follow'
    }
    return(
        fetch(domainUrl + `/deleteAppointment/${deleteappid}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result?.success) {
                message.success(result?.message)
            } 
            else 
                throw result?.message
        })
        .catch(error => {
            message.error(error.message || 'Something went wrong');
        })
    )
}



 

  


const appointmentApi = {
    CreateApiAppointment,
    DeleteAppointmentApi,
    getPatientType,
    getEmployeeType,
    CreateRescheduleAppointment
}

export {appointmentApi}
