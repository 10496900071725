import React, { useEffect, useState } from 'react'
import { Col, Row, Space, Dropdown, Form, Button, Typography, Tag, Card, Flex } from 'antd'
import { ModuleTopHeading } from '../../PageComponents';
import {
    DownOutlined
} from '@ant-design/icons'
import '../index.css'
import { useDispatch, useSelector } from 'react-redux';
import { actionsApi, checkAuthorization, domainUrl } from '../../../shared';

const { Title, Text } = Typography

const AppointmentList = () => {

    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const { adddashboardDetail, loadingAddDashboardDetail, filters, pagination } = useSelector(state => state?.dashboard)
    const [order, setOrder] = useState(1)
    const [ getid, setGetId ] = useState(null)
    const [ showdata, setShowData ] = useState(null)
    const items = [
        {
            label: <a href="#">Today</a>,
            key: '1',
        },
        {
            label: <a href="#">Monthly</a>,
            key: '2',
        },
        {
            label: <a href="#">Yearly</a>,
            key: '3',
        },
    ];


    useEffect(() => {
        dispatch(actionsApi?.getAddDashboardDetail({ ...filters, ...pagination, pageNo: 1 }))
        form.setFieldsValue({
            ...filters,
        })
        setOrder(filters?.order)
    }, [])
    const filter = (order) => {
        let data = form.getFieldsValue()
        dispatch(actionsApi?.getAddDashboardDetail({ ...data, order, ...pagination, pageNo: 1 }))
        dispatch(actionsApi?.changeAdddashboardDetailFilters({ ...data, order }))
    }
    const call = (pageNo = 1, pageSize = 10) => {
        dispatch(actionsApi?.getAddDashboardDetail({ ...filters, pageNo, pageSize }))
    }

    const onClick = ({ key }) => {
        // key = parseInt(key) + 1
        setOrder(key)
        filter(key)
    }

    useEffect(() => {
        if (getid !== null) {
            ViewPatientDetailOnly();
        }
    }, [getid]);


    const ViewPatientDetailOnly = () => {
        const { userToken } = checkAuthorization();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', userToken);
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(domainUrl + `/viewAppointment/${getid}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result?.success) {
                    result = result.data;
                    setShowData(result?.patient);
                    console.log('patient detail',result?.patient)
                } else {
                    throw new Error('Failed to fetch appointment data');
                }
            })
            .catch(error => {
                alert(error.message);
            });
    };

  return (
    <div>
        <Row gutter={[24,24]}>
            <Col xs={24} sm={24} md={24} lg={14}>
                <Flex justify='space-between' className='mb-3'>
                    <ModuleTopHeading
                        name='Appointment List'
                        className='w-100'
                    />
                    <Space style={{justifyContent:"end"}}>
                        <Dropdown
                            menu={{
                                items,
                                onClick
                            }}
                            trigger={['click']}
                            arrow
                        >
                            <Button
                                className='margin-top'
                            >
                                Today
                                <DownOutlined />
                            </Button>
                        </Dropdown>
                    </Space>
                </Flex>
                {
                    adddashboardDetail?.map((data, index)=>
                        <Space className='w-100 mb-3' style={{justifyContent:'space-between'}} key={index} >
                            <Space className='clickable' onClick={()=>{setGetId(data?.id)}}>
                                <div className='nameCircle'
                                    
                                >
                                    <div className='nameCircleinner'>
                                        {
                                            data?.firstName.charAt(0).toUpperCase() + data?.lastName.charAt(0).toUpperCase()
                                        }
                                    </div>
                                </div>
                                <div>
                                    <Title level={5} className='m-0 text-capitalize'>
                                        {
                                            data?.firstName + ' ' + data?.lastName
                                        }
                                    </Title>
                                    <Text className="greencolor">
                                        {data?.instruction}
                                    </Text>
                                </div>
                            </Space>
                            <div>
                                <Tag color="purple" className="bagde-green">
                                    {
                                        data?.fromTime
                                    }
                                </Tag>
                            </div>
                        </Space>
                    )
                }
            </Col>
            <Col xs={24} sm={24} md={24} lg={10}>
                <Card className='border-blue radius-12 height-100'>
                    <ModuleTopHeading 
                        name='Patient Details'
                    />
                    {
                        showdata?
                        <div>
                            <Space size={20} className='my-3'>
                                <div className='nameCircle'>
                                    <div className='nameCircleinner'>
                                        {
                                            showdata?.firstName.charAt(0).toUpperCase() + showdata?.lastName.charAt(0).toUpperCase()
                                        }
                                    </div>
                                </div>
                                <div>
                                    <Title level={5} className='m-0'>
                                        {
                                            showdata?.firstName + ' ' + showdata?.lastName
                                        }
                                    </Title>
                                    <Text className="gray-text">
                                        Male: {showdata?.age}
                                    </Text>
                                </div>
                            </Space>
                            <div className='line'></div>
                            <Row gutter={[24,24]} className='mb-3'>
                                <Col md={8}>
                                    <Text strong>
                                        Disease
                                    </Text>
                                </Col>
                                <Col md={16}>
                                    <Text>
                                        {
                                        showdata?.note
                                        }
                                    </Text>
                                </Col>
                                <Col md={8}>
                                    <Text strong>
                                        Contact no
                                    </Text>
                                </Col>
                                <Col md={16}>
                                    <Text>
                                        {
                                        showdata?.phoneNumber
                                        }
                                    </Text>
                                </Col>
                                <Col md={8}>
                                    <Text strong>
                                        Location
                                    </Text>
                                </Col>
                                <Col md={16}>
                                    <Text>
                                        {
                                        showdata?.address
                                        }
                                    </Text>
                                </Col>
                            </Row>  
                        </div>
                        :
                        <div>
                            <div className='line'></div>
                            <Title level={5} className='text-center fw-normal'>
                                No data show untill you click on appoinment list item
                            </Title>
                        </div>
                    }
                    
                </Card>
            </Col>
        </Row>
    </div>
  )
}

export {AppointmentList}