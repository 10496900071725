import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { domainUrl } from '../../../constants';
import { checkAuthorization } from '../../../helpers';
import { message } from 'antd';

export const getAddServicepatientDetail = createAsyncThunk('getServicepatientStatistics',
    async (object, { dispatch, getState }) => {
      const { userToken } = checkAuthorization();
      dispatch(gettingAddServicepatientDetail());
      var myHeaders = new Headers();
      myHeaders.append("Authorization", userToken);
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("pageNO", object?.pageNo);
      myHeaders.append("recordsPerPage", object?.pageSize);
      var requestOptions = {
        method: 'Post',
        headers: myHeaders,
        redirect: 'follow',
        body: JSON.stringify({ id: object?.id, order: object.order, name: object?.name }),
      };
  
      try {
        const response = await fetch(domainUrl + `/viewSerivesByPatient`, requestOptions);
        const result = await response.json();
  
        if (result?.success) {
          const filteredData = result.data.filter(item => {
            const doctorName = `${item.doctor.firstName} ${item.doctor.lastName}`.toLowerCase();
            const nurseName = `${item.nurse.firstName} ${item.nurse.lastName}`.toLowerCase();
            const searchTerm = object.name.toLowerCase();
            return doctorName.includes(searchTerm) || nurseName.includes(searchTerm);
          });
  
          dispatch(addservicepatientDataResponse(result?.patient));
          dispatch(addservicepatientDetailResponse(filteredData));
          dispatch(setPages({ pageNo: object?.pageNo, pageSize: object?.pageSize, totalRecords: filteredData.length }));
        } else {
          throw result?.message;
        }
      } catch (error) {
        dispatch(addservicepatientDetailClear());
        message.error(error);
      }
    }
  );
  

const AddServicePatientSlice = createSlice({
  name: 'servicepatient',
  initialState: {
    data: null,
    loading: false,
    addservicepatientDetail: [],
    patientdata: null,
    loadingAddservicepatientDetail: false,
    pagination: {
      pageNo: 1,
      pageSize: 10,
      totalRecords: 0
    },
    filters: {
      name: '',
      order: 1,
      id: ''
    }
  },
  reducers: {
    getPatientStatistics: (state) => {
      state.loading = true;
      state.data = null;
    },
    statisticsResponse: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    statisticsClear: (state) => {
      state.data = null;
      state.loading = false;
    },
    gettingAddServicepatientDetail: (state) => {
      state.loadingAddservicepatientDetail = true;
    },
    addservicepatientDetailResponse: (state, action) => {
      state.addservicepatientDetail = action.payload;
      state.loadingAddservicepatientDetail = false;
    },
    addservicepatientDataResponse: (state, action) => {
      state.patientdata = action.payload;
      state.loadingAddservicepatientDetail = false;
    },
    addservicepatientDetailClear: (state) => {
      state.addservicepatientDetail = [];
      state.loadingAddservicepatientDetail = false;
      state.patientdata = null;
    },
    changeAddservicepatientDetailFilters: (state, action) => {
      state.filters = action.payload;
    },
    setPages: (state, action) => {
      state.pagination = action.payload;
    }
  }
});

export const {
  getPatientStatistics, statisticsResponse, statisticsClear,
  gettingAddServicepatientDetail, addservicepatientDetailResponse, addservicepatientDataResponse, addservicepatientDetailClear,
  changeAddservicepatientDetailFilters, setPages
} = AddServicePatientSlice.actions;
export default AddServicePatientSlice.reducer;
