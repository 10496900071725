import React, { useEffect, useState } from 'react'
import { Image, Space, Row, Col, Form,Dropdown, Card, Table, Button, Flex, Typography } from 'antd'
import { MyInput, ModuleTopHeading, ActionButton, SendMsgModal, AlertModal, ViewEmployeeDrawer, AddEmployeeDrawer } from '../../components';
import { DeleteOutlined, EditOutlined, EyeOutlined, FilterOutlined } from '@ant-design/icons';
import { actionsApi, TableLoader } from '../../shared';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

const { Text } = Typography
let timer

const Employees = () => {
    
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const { addemployeeDetail, loadingAddEmployeeDetail, filters, pagination } = useSelector(state => state?.employee)
    const [ visible, setVisible ] = useState(false)
    const [ visiblemodal, setVisibleModal] = useState(false)
    const [ deleteemp, setDeleteEmp ] = useState(false)
    const [ editdrawer, setEditDrawer ] = useState(false)
    const [ editid, setEditId ] = useState(null)
    const [ deleteid, setDeleteId ] = useState(null)
    const [ viewid, setViewId ] = useState(null)
    const [order, setOrder] = useState(1)

    const items = [
        {
            label: <a href="#">A-Z</a>,
            key: '0',
        },
        {
            label: <a href="#">Z-A</a>,
            key: '1',
        },
        {
            label: <a href="#">Most downloads</a>,
            key: '2',
        },
        {
            label: <a href="#">Less downloads</a>,
            key: '3',
        },
    ];

    useEffect(() => {
        dispatch(actionsApi?.getAddEmployeeDetail({ ...filters, ...pagination, pageNo: 1 }))
        form.setFieldsValue({
            ...filters,
        })
        setOrder(filters?.order)

    }, [])
    const filter = (order) => {
        let data = form.getFieldsValue()
        dispatch(actionsApi?.getAddEmployeeDetail({ ...data, order, ...pagination, pageNo: 1 }))
        dispatch(actionsApi?.changeAddemployeeDetailFilters({ ...data, order }))
    }
    const call = (pageNo = 1, pageSize = 10) => {
        dispatch(actionsApi?.getAddEmployeeDetail({ ...filters, pageNo, pageSize }))
    }

    function searchHandler(name, pageNo=1, pageSize=20) {
        dispatch(actionsApi?.getAddEmployeeDetail({ ...filters, name, pageNo, pageSize }))
    }

    const onClick = ({ key }) => {
        key = parseInt(key) + 1
        setOrder(key)
        filter(key)
    }
    function debounce(func, delay) {
        return function(...args) {
            clearTimeout(timer)
            timer = setTimeout(() => {
                func.apply(this, args)
            }, delay)
        };
    }
    const debouncedSearchHandler = debounce(searchHandler, 400)
  
  
    const columns = [
        {
            title: 'Name',
            dataIndex: 'firstName',
            render: (_, row) => `${row.firstName} ${row.lastName}`
        },
        {
            title: 'Age',
            dataIndex: 'age',
        },
        {
            title: 'Role',
            dataIndex: 'role',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (status) => {
                return (
                    status === 1 ? (
                        <Space align='center'>
                            <img src="/assets/icons/online.png" width={'12px'} alt="" />
                            <Text style={{transform:"translateY(-2px)",display:'block'}}>Active</Text>
                        </Space>
                    ) : (
                        <Space>
                            <img src="/assets/icons/offline.png" width={'12px'} alt="" />
                            <Text style={{transform:"translateY(-2px)",display:'block'}}>Inactive</Text>
                        </Space>
                    )
                );
            }
        },
        {
            title: 'Action',
            key: 'action',
            fixed: 'right',
            align:'right',
            width: 150,
            render: (_, row) => (
                <Space>
                    <ActionButton
                        title='View Item'
                        icon={<EyeOutlined />}
                        onClick={() => { setVisible(true); setViewId(row?.id) }}
                        type='ghost'

                    />
                    <ActionButton
                        title='Edit Box'
                        icon={<EditOutlined />}
                        onClick={()=> {setEditDrawer(true);setEditId(row?.id)}}
                        type='ghost'

                    />
                    <ActionButton
                        title='Add Message'
                        icon={<Image src='/assets/icons/msg.png' width={16} preview={false} />}
                        onClick={() => { setVisibleModal(true) }}
                        type='ghost'

                    />
                    <ActionButton
                        title='Delete Box'
                        icon={<DeleteOutlined className='danger-color' />}
                        onClick={() => { setDeleteEmp(true); setDeleteId({name:row?.firstName,id:row?.id}) }}
                        type='ghost'
                        danger
                    />
                </Space>
            ),
        },
    ];
    

  
    return (
        <div>
            <Card className='radius-12 border0 shadow-c'>
                <Row gutter={[12,12]}>
                    <Col lg={14}>
                        <ModuleTopHeading
                            name='Employees'
                            title='Add employee'
                            onClick={()=> setEditDrawer(true)}
                        />
                    </Col>
                    <Col  span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <Flex gap={'small'} className='w-100' align='end'>
                                <MyInput
                                    name='search'
                                    label='Search'
                                    placeholder='Search'
                                    onChange={(e)=> debouncedSearchHandler(e.target.value, 1, 20)}
                                    className='w-100'
                                />
                                <Dropdown
                                    menu={{
                                        items,
                                        onClick
                                    }}
                                    trigger={['click']}
                                    arrow
                                    icon={<FilterOutlined />}
                                    className='margin-top'
                                >
                                    <Button
                                        icon={<FilterOutlined />}
                                    >
                                        Filter
                                    </Button>
                                </Dropdown>
                            </Flex>
                        </Form>
                    </Col>
                    <Col span={24}>
                        <Table 
                            size='large'
                            columns={columns} 
                            dataSource={addemployeeDetail}
                            scroll={{x: 1000,}}
                            pagination={{
                                hideOnSinglePage: true,
                                total: pagination?.totalRecords,
                                pageSize: pagination?.pageSize,
                                defaultPageSize: pagination?.pageSize,
                                current: pagination?.pageNo,
                                size: "default",
                                pageSizeOptions: ['10', '20', '50', '100'],
                                onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                                showTotal: (total) => <Button>Total: {total}</Button>,
                            }}
                            loading={
                                {
                                    ...TableLoader,
                                    spinning: loadingAddEmployeeDetail
                                }
                            }
                        />
                    </Col>
                </Row>
            </Card>

            <AddEmployeeDrawer 
                editdrawer={editdrawer}
                editid={editid}
                getAddEmployeeDetail={call}
                onClose={()=>{setEditDrawer(false);setEditId(null)}}
            />
            <ViewEmployeeDrawer 
                visible={visible}
                viewid={viewid}
                onClose={()=>{setVisible(false);setViewId(null)}}
            />
            <SendMsgModal 
                visiblemodal={visiblemodal}
                onClose={()=>setVisibleModal(false)}
            />
            <AlertModal 
                deleteemp={deleteemp}
                deleteid={deleteid}
                getAddEmployeeDetail={call}
                onClose={()=>{ setDeleteEmp(false); setDeleteId(null)}}
            />
        </div>

    )
}

export {Employees}