import React, { useEffect } from 'react'
import { Card, Col, Flex, Modal, Row, Table, Typography, Divider, Spin } from 'antd'
import { ModuleTopHeading } from '../../PageComponents';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { actionsApi } from '../../../shared';

const { Text } = Typography

const PrescriptionViewModal = ({visible, onClose, medid}) => {
  
  const dispatch = useDispatch()
  const { addviewmedDetail, nursedetail, loadingViewMedDetail } = useSelector(state => state?.viewmedicineprescription)

  useEffect(() => {
    if(medid){
      dispatch(actionsApi?.getViewMedpresDetail({ id: medid?.id}))
    }
  }, [medid])

  
    
    const data = addviewmedDetail?.prescription?.medicines?.map(medicine => ({
      key: medicine.id,
      date: medicine.date,
      morning: medicine.morning,
      afterNoon: medicine.afterNoon,
      night: medicine.night,
    }));
  
    const columns = [
        {
            title: 'Date',
            dataIndex: 'date',
            render: (date) => (
              <Flex>
                <div>
                    {moment(date).format('MMM Do YYYY')}
                </div>
            </Flex>
            ),
            width:200
        },
        {
            title: 'Morning',
            dataIndex: 'morning',
            align:'center',
            render: (morning) => {
              return (
                <>
                  {morning === '1' ? (
                    <img src="/assets/icons/uncheck.png" width={20} alt="" />
                  ) : morning === '2' ? (
                    <img src="/assets/icons/check-box.png" width={20} alt="" />
                  ) : <></>}
                </>
              );
            }            
        },
        {
            title: 'Afternoon',
            dataIndex: 'afterNoon',
            align:'center',
            render: (afterNoon) => {
              return (
                <>
                  {afterNoon === '1' ? (
                    <img src="/assets/icons/uncheck.png" width={20} alt="" />
                  ) : afterNoon === '2' ? (
                    <img src="/assets/icons/check-box.png" width={20} alt="" />
                  ) : <></>}
                </>
              );
            } 
        },
        {
            title: 'Night',
            dataIndex: 'night',
            align:'center',
            render: (night) => {
              return (
                <>
                  {night === '1' ? (
                    <img src="/assets/icons/uncheck.png" width={20} alt="" />
                  ) : night === '2' ? (
                    <img src="/assets/icons/check-box.png" width={20} alt="" />
                  ) : <></>}
                </>
              );
            } 
        },
    ];
    


  

  return (
    <Modal
        title={visible?'#'+medid.id.substr(0,5) + ' ' + moment(medid?.date).format('MMM Do YYYY'):''}
        centered
        open={visible}
        onOk={onClose}
        onCancel={onClose}
        width={1000}
        footer={false}
      >
        <Divider className='my-2 divder-color' />
        <Flex gap={'small'} className='pt-2'>
          <Text strong>Nurse:</Text>
          <Text>
            {
              '#'+nursedetail?.id.substr(0,3) + ' ' + nursedetail?.firstName + ' ' + nursedetail?.lastName
            }
          </Text>
        </Flex>
        <Flex gap="small" vertical className="pb-2">
          <Text strong>Medicine:</Text>
            {loadingViewMedDetail ? (
              <Spin spinning={loadingViewMedDetail} />
            ) : (
              <>
                {addviewmedDetail?.uniqueMedicines?.map((data, index) => (
                  <Flex vertical key={index} className="px-3">
                    <Text>
                      <strong className="text-capitalize">{`${index + 1}) ${data?.medicine}`}</strong> - {data?.description}
                    </Text>
                    <Text>{`Note: ${data?.note}`}</Text>
                  </Flex>
                ))}
              </>
            )}
        </Flex>
        <Card>
          <Row gutter={[12,12]}>
            <Col span={24}>
                <ModuleTopHeading
                    name='Prescription'
                />
            </Col>
            <Col span={24}>
                <Table
                    size='large'
                    columns={columns} 
                    dataSource={data}
                    scroll={{x: 500, y:300}}
                    pagination={false}
                />
            </Col>
          </Row>
        </Card>
      </Modal>
  )
}

export {PrescriptionViewModal}