import React, { useEffect, useState } from 'react'
import { Avatar, Card, Drawer, Flex, Typography, message } from 'antd'
import { PatientPrescriptionTable } from './PatientPrescriptionTable'
import { PatientVisitTable } from './PatientVisitTable'
import { actionsApi, checkAuthorization, domainUrl } from '../../shared'
import { useDispatch, useSelector } from 'react-redux'

const { Title, Text } = Typography

const PatientServiceViewDrawer = ({viewservice,onClose, serviceid }) => {
    
    const dispatch = useDispatch()
    const { patientdata,addservicepatientDetail, filters, pagination } = useSelector(state => state?.servicepatient)
   
    useEffect(() => {
        if(serviceid){
            dispatch(actionsApi?.getAddServicepatientDetail({ ...filters, ...pagination, pageNo: 1, id: serviceid  }))
        }
    }, [serviceid])

  return (
    <div>
        <Drawer
                onClose={onClose}
                open={viewservice}
                width={1400}
                footer={false}
            >
                <Card className='radius-12 border0'>
                    <div className='mb-3'>
                        <Title level={5} className='brand-color'>
                            Patient Services 
                        </Title>
                    </div>
                    <Flex gap={'small'} className='mb-3'>
                        <Avatar
                            size={50}
                            icon={<img src={patientdata?.profileImage} width={120} />}
                        />
                        <div>
                            <Title level={5} className='m-0'>
                                {patientdata?.firstName}
                            </Title>
                            <Text className='grayish fs-12'>
                                Patient
                            </Text>
                        </div>
                    </Flex>
                    <Card className='radius-12 border0 shadow-in mb-3'>
                        <PatientPrescriptionTable serviceid={serviceid} />
                    </Card>
                    <Card className='radius-12 border0 shadow-in'>
                        <PatientVisitTable serviceid={serviceid} />
                    </Card>
                </Card>
        </Drawer>
    </div>
  )
}

export {PatientServiceViewDrawer}