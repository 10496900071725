import { PlusOutlined } from "@ant-design/icons"
import {Row, Col, Space, Button, Typography, Image} from "antd"
export const ModuleTopHeadingBtn=({name, onClick})=>{
    return (
        <Row>
            <Col span={24}>
                <Space className="align-center">
                    <Typography.Text >
                        {
                            name
                        }
                    </Typography.Text>
                    {
                        onClick ?
                        <Button 
                            type="primary" 
                            className="center"
                            shape="circle"
                            size="small"
                            style={{minWidth: 20,width:20,height:20}}
                            onClick={onClick} icon={<PlusOutlined style={{fontSize:12}} />}
                        >
                        </Button>
                        :<></>
                    }
                </Space>
            </Col>
        </Row>
    )
}