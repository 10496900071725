import React, { useState } from 'react'
import { Button, Col, Flex, Form, Image, message, Row, Typography } from 'antd'
import { HospiceInput } from '../../input'
import { useNavigate } from 'react-router-dom'
import { apiCalls } from '../../../shared/Apis'
import { ArrowRightOutlined } from '@ant-design/icons'

const { Title } = Typography

const SendEmail = () => {
    const [form] = Form.useForm()
    const navigate = useNavigate()
    const [ loading, setLoading ] = useState(false)

    const SendEmailForgot = async (formData) => {
        setLoading(true);
        try {
            const result = await apiCalls?.forgotpasswordApi.SendEmailForgot(formData);
            setLoading(false);
            if (result) {
                form.resetFields();
                navigate('/otp')
            }
        } catch (error) {
            message.error(error);
            setLoading(false);
        }
    }

    const onFinish = async () => {
        try {
            const formData = await form.validateFields();
            SendEmailForgot(formData);
        } catch (error) {
            console.error("Form validation error:", error);
        }
    };

  return (
    <div className='login-form-cover'>
        <Flex justify="center">
            <Row className="login-form border-grey" justify={"center"}>
                <Col xs={24} sm={24} md={14}>
                    <Flex vertical align="center" gap={'large'} justify="center" className="height-100">
                        <Flex vertical align="center" justify="center">
                            <Image
                                src={"./assets/logo.png"}
                                alt='Store App Admin Panel'
                                preview= {false}
                                width={'150px'}
                            />
                            <Title 
                                level={5} 
                                className="text-center brand-color fs-15"
                                >
                                Forgot password
                            </Title>
                        </Flex>
                        <Form
                            form={form}
                            layout="vertical"
                            initialValues={{
                                remember: true,
                            }}
                            style={{ width: '100%' }}
                            onFinish={onFinish}
                        >
                            <Row>
                                <Col span={24}>
                                    <HospiceInput
                                        autoFocus
                                        name="email"
                                        type='email'
                                        label="Email Address"
                                        size='large'
                                        required
                                        message='please enter your email address'
                                        placeholder='Enter your email address'
                                        value={form.getFieldValue('email') || ''}
                                    />
                                </Col>
                                <Col span={24}>
                                    <Flex justify='center' gap={5}>
                                        <Button 
                                            type="primary"
                                            htmlType="submit" 
                                            loading={loading}
                                            >
                                            Continue
                                        </Button>
                                        <Button shape='circle' className='borderbrand' onClick={()=>navigate('/')}>
                                            <Flex justify='center' align='center'>
                                                <ArrowRightOutlined  />
                                            </Flex>
                                        </Button>
                                    </Flex>
                                </Col>
                            </Row>
                        </Form>
                    </Flex>
                </Col>
            </Row>
        </Flex>
    </div>
  )
}

export {SendEmail}