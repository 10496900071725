import React, { useState } from 'react';
import { Flex, Typography } from 'antd';
import { CloseCircleOutlined, EyeOutlined } from '@ant-design/icons';
import { ActionButton } from '../../PageComponents';
import { ViewPatientDetailModal } from '../ViewPatientDetailModal';
import { ReasonToCancelModal } from '../ReasonToCancelModal';
import '../index.css';

const { Text } = Typography;

const CalendarEvents = ({ events }) => {
    const [visiblemodal, setVisibleModal] = useState(false);
    const [reasonmodal, setReasonModal] = useState(false);
    const [ eventid, setEventId ] = useState(null)

    
    if (!Array.isArray(events)) {
        return<></>;
    } 

    console.log('events',events)
    return (
        <>
            {events.map((event, index) => (
                <Flex key={index} className={
                        event.status === 1 ? 'block-event status-pending':
                        event.status === 2 ? 'block-event status-approved':
                        event.status === 3 ? 'block-event status-cancel':
                        'block-event'
                    }
                    >
                    <Text className='text-white fs-12'>
                        {event.status === 1 ? 'Unvisited' :
                         event.status === 2 ? 'Visited' :
                         event.status === 3 ? 'Cancelled' : ''}
                    </Text>
                    <Flex>
                        <ActionButton 
                            title='View details'
                            onClick={() => {setVisibleModal(true);setEventId(event)}}
                            icon={<EyeOutlined className='text-white' />}
                            type='ghost'
                        />
                        {event.status === 1 && (
                            <ActionButton 
                                title='Cancel appointment'
                                onClick={() => setReasonModal(true)}
                                icon={<CloseCircleOutlined className='text-white' />}
                                type='ghost'
                            />
                        )}
                    </Flex>
                </Flex>
            ))}
            <ViewPatientDetailModal
                visiblemodal={visiblemodal}
                eventid={eventid}
                onClose={() =>{ setVisibleModal(false);setEventId(null)}}
            />
            <ReasonToCancelModal
                reasonmodal={reasonmodal}
                onClose={() => setReasonModal(false)}
            />
        </>
    );
};

export { CalendarEvents };
