import React, { useEffect, useState } from 'react'
import { Space, Row, Col, Form, Typography, Image, Card, Drawer, Button, Flex } from 'antd'
import { CloseCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { ActionButton, ModuleTopSmhead } from '../PageComponents'
import Dragger from 'antd/es/upload/Dragger'
import { HospiceInput } from '../input';
import { apiCalls } from '../../shared/Apis';
import { checkAuthorization, domainUrl } from '../../shared';
import { MySelect } from '../Forms';
import { DeleteDocModal } from '../Modals';

const AddEmployeeDrawer = ({ editdrawer,onClose,getAddEmployeeDetail,editid }) => {

    const [form] = Form.useForm()

    const [loading, setLoading] = useState(false)
    const { userToken } = checkAuthorization();
    const [ rolelookup, setRoleLookUp ] = useState([])
    const [fileList, setFileList] = useState([])
    const [fileListid, setFileListId] = useState([])
    const [ docdata, setDocData ] = useState(null)
    const [ idcardsmodal, setIdCardsModal ] = useState(false)
    const [ idcardsdelete, setIdCardsDelete ] = useState(null)
    const [ edudocdelete, setEduDocDelete ] = useState(null)

    useEffect(() => {
        if(editdrawer)
            getRoleapi()
        if (editdrawer && editid){ 
            EditEmployee(editid)
        }
        else 
            form.resetFields()
    }, [editdrawer, editid]);

    const updateCreateApiEmployee = async () => {
        setLoading(true);
        let data = form.getFieldsValue();
        let formData = new FormData();
    
        formData.append('name', data?.name);
        formData.append('profileImage', data?.profileImage);
        formData.append('title', data?.title);
        formData.append('email', data?.email);
        formData.append('password', data?.password);
        formData.append('firstName', data?.firstName);
        formData.append('lastName', data?.lastName);
        formData.append('age', data?.age);
        formData.append('phoneNumber', data?.phoneNumber);
        formData.append('address', data?.address);
        formData.append('specialist', data?.specialist);
        formData.append('education', data?.education);
    
        const roleId = rolelookup?.find(role => role?.name === data?.name)?.id;
        formData.append('roleID', roleId);

        for (let i = 0; i < fileList?.length; i++) {
            formData.append('educationalDocs[]', fileList[i]?.originFileObj);
        }
        for (let i = 0; i < fileListid?.length; i++) {
            formData.append('idCards[]', fileListid[i]?.originFileObj);
        }
    
        formData.append('id', editid);
    
        try {
            const result = await apiCalls?.employeeApi?.updateCreateApiEmployee(formData, editid);
            setLoading(false);
            
            if (result) {
                form.resetFields();
                onClose();
                getAddEmployeeDetail();
            } else {
                console.error('Error: Failed to save data to the database');
            }
        } catch (error) {
            console.error('Error updating/creating employee:', error);
            setLoading(false);
        }
    }
    

    const onFinish = async () => {
        try {
            const formData = await form.validateFields();
            updateCreateApiEmployee(formData);
        } catch (error) {
            console.error("Form validation error:", error);
        }
        
    };

    // get role id in dropdown
    const getRoleapi = async () => {
        let result= await apiCalls?.employeeApi?.getRoleapi()
        if(result)
        setRoleLookUp(result)
    }


    // edit patient api
    const EditEmployee = (id) => {
        const { userToken } = checkAuthorization();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', userToken);
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(domainUrl + `/viewEmployee/${id}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result?.data);
                if (result?.success) {
                    result = result.data;
                    form.setFieldsValue({
                        ...result,
                        name: result?.role
                    });
                    setDocData(result)
                    
                } else {
                    throw new Error('Failed to fetch employee data');
                }
            })
            .catch(error => {
                alert(error.message);
            });
    };
    
    
    

    const uploadProps = {
        name: 'profileImage',
        multiple: false,
        action: domainUrl + '/createEmployee',
        headers: {
            Authorization: userToken,
        },
        onChange(info) {
            const { file } = info;
            if (file.status !== 'uploading') {
                console.log(file, info.fileList);
                form.setFieldsValue({ ...form.getFieldsValue(), profileImage: file?.originFileObj })
            }
        },
    }

    const propMult = {
        name: 'educationalDocs',
        multiple: true,
        action: domainUrl + '/createEmployee',
        headers: {
            Authorization: userToken,
        },
        onChange(info) {
            const { fileList } = info;
            setFileList([...fileList]);
            if (fileList.length > 0) {
                const educationalDocsFiles = fileList.map(file => file.originFileObj);
                form.setFieldsValue({ ...form.getFieldsValue(), educationalDocs: educationalDocsFiles });
            } else {
                form.setFieldsValue({ ...form.getFieldsValue(), educationalDocs: [] });
            }
        },
    }

    const propMultlast = {
        name: 'idCards',
        multiple: true,
        action: domainUrl + '/createEmployee',
        headers: {
            Authorization: userToken,
        },
        
        onChange(info) {
            const { fileList } = info;
            setFileListId([...fileList]);
            if (fileList.length > 0) {
                const idCardFiles = fileList.map(file => file.originFileObj);
                form.setFieldsValue({ ...form.getFieldsValue(), idCards: idCardFiles });
            } else {
                form.setFieldsValue({ ...form.getFieldsValue(), idCards: [] });
            }
        },
    };

    const handleDeleteProfileImage = () => {
        form.setFieldsValue({ ...form.getFieldsValue(), profileImage: null });
        setDocData({ ...docdata, profileImage: null });
    };


    

    return (
        <div>
            <Drawer
                onClose={onClose}
                open={editdrawer}
                width={1000}
                footer={false}
            >
                <Card className='radius-12 border0 shadow-c'>
                    <div className='mb-3'>
                        <Typography.Title level={5} className='brand-color'>
                            {
                                editid? 'Edit employee' : 'Add new employee'
                            }
                        </Typography.Title>
                    </div>
                    <Form
                        form={form}
                        layout="vertical"
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                    >
                        <Space direction='vertical' size={15} className='w-100'>
                            <Card className='shadow-in border0 radius-12'> 
                                <Row gutter={16}>
                                    <Col span={24}>
                                        <div className='mb-3'>
                                            <ModuleTopSmhead
                                                name='Role'
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={12}>
                                        <MySelect
                                            label= 'Role'
                                            name="name" 
                                            required
                                            message='Please choose role'
                                            value={form.getFieldValue('name') || ''}
                                            options={rolelookup}
                                        />
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={12}>
                                        <HospiceInput
                                            name='title'
                                            label='Title'
                                            placeholder='Enter title'
                                            required
                                            message='Please enter title'
                                            value={form.getFieldValue("title") || ''} 
                                        />
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={12}>
                                        <HospiceInput
                                            name='email'
                                            label='Email address'
                                            placeholder='Enter email address'
                                            required
                                            message='Please enter email address'
                                            value={form.getFieldValue("email") || ''} 
                                        />
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={12}>
                                        <HospiceInput
                                            name='password'
                                            type='password'
                                            label='Password'
                                            placeholder='Enter password'
                                            required = {editid? false: true}
                                            message= {editid? ()=>{} : 'Please enter password'}
                                            value={form.getFieldValue("password") || ''}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                            <Card className='shadow-in border0 radius-12'> 
                                <Row gutter={16}>
                                    <Col span={24}>
                                        <div className='mb-3'>
                                            <ModuleTopSmhead
                                                name='Personal Details'
                                            />
                                        </div>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item name='profileImage'>
                                            <div className='my-3 width-drag'>
                                                {
                                                    editid ?
                                                    <>
                                                        {docdata?.profileImage ? (
                                                            <Flex justify='center' style={{ position: 'relative', width: 150, margin: 'auto' }}>
                                                                <Image
                                                                    src={docdata?.profileImage}
                                                                    width={100}
                                                                    height={100}
                                                                    className='radius-12'
                                                                />
                                                                <div className='over-img'>
                                                                    <ActionButton
                                                                        icon={<CloseCircleOutlined className='danger-color' />}
                                                                        onClick={handleDeleteProfileImage}
                                                                        type='ghost'
                                                                    />
                                                                </div>
                                                            </Flex>
                                                        ) : (
                                                            <Dragger {...uploadProps} className="dragcs">
                                                                <p className="ant-upload-drag-icon">
                                                                    <Image src='/assets/icons/upload.png' width={'41.6px'} preview={false} />
                                                                </p>
                                                                <p className="ant-upload-text fs-12 grayish">Upload Profile Picture</p>
                                                            </Dragger>
                                                        )}
                                                    </>
                                                    :
                                                    <Dragger {...uploadProps} className="dragcs">
                                                        <p className="ant-upload-drag-icon">
                                                            <Image src='/assets/icons/upload.png' width={'41.6px'} preview={false} />
                                                        </p>
                                                        <p className="ant-upload-text fs-12 grayish">Upload Profile Picture</p>
                                                    </Dragger>
                                                }
                                            </div>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={12}>
                                        <HospiceInput
                                            name='firstName'
                                            label='First name'
                                            placeholder='Enter first name'
                                            required
                                            message='Please enter first name'
                                            value={form.getFieldValue("firstName") || ''} 
                                        />
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={12}>
                                        <HospiceInput
                                            name='lastName'
                                            label='Last name'
                                            placeholder='Enter last name'
                                            required
                                            message='Please enter last name'
                                            value={form.getFieldValue("lastName") || ''}
                                        />
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={12}>
                                        <HospiceInput
                                            name='age'
                                            label='Age'
                                            placeholder='Enter phone number'
                                            required
                                            message='Please enter phone number'
                                            value={form.getFieldValue("age") || ''}
                                        />
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={12}>
                                        <HospiceInput
                                            name='phoneNumber'
                                            label='Phone number'
                                            placeholder='Enter phone number'
                                            required
                                            message='Please enter phone number'
                                            value={form.getFieldValue("phoneNumber") || ''}
                                        />
                                    </Col>
                                    <Col span={24}>
                                        <HospiceInput
                                            textArea
                                            name='address'
                                            label='Address'
                                            placeholder='Enter street address'
                                            required
                                            message='Please enter street address'
                                            value={form.getFieldValue("address") || ''}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                            <Card className='shadow-in border0 radius-12'> 
                                <Row gutter={16}>
                                    <Col span={24}>
                                        <div className='mb-3'>
                                            <ModuleTopSmhead
                                                name='Educational Details'
                                            />
                                        </div>
                                    </Col>
                                    <Col span={24}>
                                        <HospiceInput
                                            name='specialist'
                                            label='Specialist'
                                            placeholder='Enter specialist'
                                            required
                                            message='Please enter specialist'
                                            value={form.getFieldValue("specialist") || ''}
                                        />
                                    </Col>
                                    <Col span={24}>
                                        <HospiceInput
                                            textArea
                                            name='education'
                                            label='Education'
                                            placeholder='Enter educational details'
                                            required
                                            message='Please enter educational details'
                                            value={form.getFieldValue("education") || ''}
                                        />
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item name='educationalDocs'>
                                            <div className='my-3 width-drag'>
                                                <Dragger {...propMult} className="dragcs">
                                                    <p className="ant-upload-drag-icon">
                                                        <Image src='/assets/icons/upload.png' width={'41.6px'} preview={false} />
                                                    </p>
                                                    <Typography.Text className="fs-12 grayish">Upload Educational Documents</Typography.Text>
                                                </Dragger>
                                            </div>
                                        </Form.Item>
                                    </Col>
                                    {
                                        editid?
                                        <Col xs={24} sm={24} md={12}>
                                            <Space direction='vertical' size={10} className='w-100'>
                                                {
                                                    docdata?.education_docs?.map((data,index)=>
                                                        <div className='viewDownload space-between-align' key={index}>
                                                            <Typography.Text className='gray-text fs-12'>
                                                                {
                                                                    data?.name
                                                                }
                                                            </Typography.Text>
                                                            <ActionButton
                                                                title='Delete'
                                                                icon={<DeleteOutlined className='danger-color' />}
                                                                onClick={() => {setIdCardsModal(true);setEduDocDelete({id:data?.id,name:data?.name})}}
                                                                type='ghost'
                                                                
                                                            />
                                                        </div>
                                                    )
                                                }
                                            </Space>
                                        </Col>
                                        :
                                        <></>
                                    }
                                </Row>
                            </Card>
                            <Card className='shadow-in border0 radius-12'> 
                                <Row gutter={16}>
                                    <Col span={24}>
                                        <div className='mb-3'>
                                            <ModuleTopSmhead
                                                name='Identity Card'
                                            />
                                        </div>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item name='idCards'>
                                            <div className='my-3 width-drag'>
                                                <Dragger {...propMultlast} className="dragcs">
                                                    <p className="ant-upload-drag-icon">
                                                        <Image src='/assets/icons/upload.png' width={'41.6px'} preview={false} />
                                                    </p>
                                                    <Typography.Text className="fs-12 grayish">Upload Identity Card</Typography.Text>
                                                </Dragger>
                                            </div>
                                        </Form.Item>
                                    </Col>
                                    {
                                        editid?
                                        <Col xs={24} sm={24} md={12}>
                                            <Space direction='vertical' size={10} className='w-100'>
                                                {
                                                    docdata?.id_cards?.map((data,index)=>
                                                        <div className='viewDownload space-between-align' key={index}>
                                                            <Typography.Text className='gray-text fs-12'>
                                                                {
                                                                    data?.name
                                                                }
                                                            </Typography.Text>
                                                            <ActionButton
                                                                title='Delete'
                                                                icon={<DeleteOutlined className='danger-color' />}
                                                                onClick={() => {setIdCardsModal(true);setIdCardsDelete({id:data?.id,name:data?.name})}}
                                                                type='ghost'
                                                                
                                                            />
                                                        </div>
                                                    )
                                                }
                                            </Space>
                                        </Col>
                                        :
                                        <></>
                                    }
                                </Row>
                            </Card>
                            <Flex justify='end' gap={'small'}>
                                <Button 
                                    onClick={onClose}
                                >
                                    Cancel
                                </Button>
                                <Button  
                                    type="primary"
                                    htmlType="submit"
                                    loading={loading}
                                    >
                                    {
                                        editid? 'Update' : 'Save'
                                    }
                                </Button>
                            </Flex>
                        </Space>
                    </Form>
                </Card>
            </Drawer>
            <DeleteDocModal 
                idcardsmodal={idcardsmodal}
                idcardsdelete={idcardsdelete}
                edudocdelete={edudocdelete}
                EditEmployee={EditEmployee}
                onClose={()=>{setIdCardsModal(false);setIdCardsDelete(null);setEduDocDelete(null)}}
            />
        </div>
    )
}

export { AddEmployeeDrawer }
