import React, { useEffect, useState } from 'react'
import { Space, Row, Col, Form, Typography, Image, Card, DatePicker, Drawer, Button, Flex } from 'antd'
import { CloseCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { ActionButton, ModuleTopSmhead } from '../PageComponents'
import Dragger from 'antd/es/upload/Dragger'
import { HospiceInput } from '../input';
import { apiCalls } from '../../shared/Apis';
import { checkAuthorization, domainUrl } from '../../shared';
import moment from 'moment';
import { DeletePatientDocModal } from '../Modals';

const AddPatientDrawer = ({ visible, onClose, editpatient, getAddPatientDetail }) => {

    const [form] = Form.useForm()

    const [loading, setLoading] = useState(false)
    const { userToken } = checkAuthorization();
    const [ geteditdata, setGetEditData ] = useState('')
    const [ fileList, setFileList] = useState([])
    const [ fileListid, setFileListid] = useState([])
    const [ deletepatientdoc, setDeletePatientDoc ] = useState(false)
    const [ deletepatid, setDeletePatId ] = useState(null)
    const [ deletepatmed, setDeletePatMed ] = useState(null)

    useEffect(() => {
        if (visible && editpatient) {
            EditPatientApi(editpatient)
        } else {
            form.resetFields()
        }
    }, [visible, editpatient])

    const updateCreateApi = async () => {
        setLoading(true);
        let data = form.getFieldsValue();
        let formData = new FormData();
    
        formData.append('profileImage', data?.profileImage);
        formData.append('firstName', data?.firstName);
        formData.append('lastName', data?.lastName);
        formData.append('email', data?.email);
        formData.append('phoneNumber', data?.phoneNumber);
        formData.append('dob', data?.dob ? data.dob.toISOString() : ''); 
        formData.append('age', data?.age);
        formData.append('address', data?.address);
        formData.append('note', data?.note);
        formData.append('careTaker_firstName', data?.careTaker_firstName);
        formData.append('careTaker_lastName', data?.careTaker_lastName);
        formData.append('careTaker_email', data?.careTaker_email);
        formData.append('careTaker_phoneNumber', data?.careTaker_phoneNumber);
        formData.append('careTaker_relationship', data?.careTaker_relationship);
        formData.append('careTaker_secondryNumber', data?.careTaker_secondryNumber);
        formData.append('careTaker_address', data?.careTaker_address);
    
        for (let i = 0; i < fileList?.length; i++) {
            formData.append('medicalReport[]', fileList[i]?.originFileObj);
        }
        for (let i = 0; i < fileListid?.length; i++) {
            formData.append('idCards[]', fileListid[i]?.originFileObj);
        }
        formData.append('id', editpatient);
        try {
            const result = await apiCalls.patientApi.updateCreateApi(formData,editpatient);
            setLoading(false);
            getAddPatientDetail();
            if (result) {
                form.resetFields();
                onClose();
            }
        } catch (error) {
            console.error('Error updating/creating patient:', error);
            setLoading(false);
        }
    }
    

    const onFinish = async () => {
        try {
            const formData = await form.validateFields();
            updateCreateApi(formData);
        } catch (error) {
            console.error("Form validation error:", error);
        }
    };

    // edit patient api
    const EditPatientApi = (id) => {
        const { userToken } = checkAuthorization()
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json")
        myHeaders.append('Authorization', userToken);
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        }
        fetch(domainUrl + `/viewPatient/${id}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result?.data)
                if (result?.success) {

                    const patientData = result.data;
                        const initialValues = {};
                        // Set individual fields
                        initialValues['firstName'] = patientData.firstName || '';
                        initialValues['lastName'] = patientData.lastName || '';
                        initialValues['email'] = patientData.email || '';
                        initialValues['phoneNumber'] = patientData.phoneNumber || '';
                        initialValues['dob'] = patientData.dob ? moment(patientData.dob) : null;
                        initialValues['age'] = patientData.age || '';
                        initialValues['address'] = patientData.address || '';
                        initialValues['note'] = patientData.note || '';

                        // Set caretaker fields
                        initialValues['careTaker_firstName'] = patientData.care_taker?.firstName || '';
                        initialValues['careTaker_lastName'] = patientData.care_taker?.lastName || '';
                        initialValues['careTaker_email'] = patientData.care_taker?.email || '';
                        initialValues['careTaker_phoneNumber'] = patientData.care_taker?.phoneNumber || '';
                        initialValues['careTaker_relationship'] = patientData.care_taker?.relationship || '';
                        initialValues['careTaker_secondryNumber'] = patientData.care_taker?.secondryNumber || '';
                        initialValues['careTaker_address'] = patientData.care_taker?.address || '';

                        form.setFieldsValue(initialValues);

                        setGetEditData(patientData)

                        }
                else {
                    throw 'error'
                }
            })
            .catch(error => { alert(error) })
    }

    const uploadProps = {
        name: 'profileImage',
        multiple: false,
        action: domainUrl + '/createPatient',
        headers: {
            Authorization: userToken,
        },
        onChange(info) {
            const { file } = info;
            if (file.status !== 'uploading') {
                console.log(file, info.fileList);
                form.setFieldsValue({ ...form.getFieldsValue(), profileImage: file?.originFileObj })
            }
        },
    }

    const propMult = {
        name: 'medicalReport',
        multiple: true,
        action: domainUrl + '/createPatient',
        headers: {
            Authorization: userToken,
        },
        onChange(info) {
            const { fileList } = info;
            setFileList([...fileList]);
            if (fileList.length > 0) {
                const medicalReportFiles = fileList.map(file => file.originFileObj);
                form.setFieldsValue({ ...form.getFieldsValue(), medicalReport: medicalReportFiles });
            } else {
                form.setFieldsValue({ ...form.getFieldsValue(), medicalReport: [] });
            }
        },
    }

    const propMultlast = {
        name: 'idCards',
        multiple: true,
        action: domainUrl + '/createPatient',
        headers: {
            Authorization: userToken,
        },
        
        onChange(info) {
            const { fileList } = info;
            setFileListid([...fileList]);
            if (fileList.length > 0) {
                const idCardFiles = fileList.map(file => file.originFileObj);
                form.setFieldsValue({ ...form.getFieldsValue(), idCards: idCardFiles });
            } else {
                form.setFieldsValue({ ...form.getFieldsValue(), idCards: [] });
            }
        },
    };
    

    // Handle delete profile image
    const handleDeleteProfileImage = () => {
        form.setFieldsValue({ ...form.getFieldsValue(), profileImage: null });
        setGetEditData({ ...geteditdata, profileImage: null });
    };


    return (
        <div>
            <Drawer
                onClose={onClose}
                open={visible}
                width={1000}
                footer={false}
            >
                <Form
                    form={form}
                    layout="vertical"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                >
                    <Space direction='vertical' size={15} className='w-100'>
                        <Card className='shadow-in border0 radius-12'>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <div className='mb-3'>
                                        <ModuleTopSmhead
                                            name='Personal Details'
                                        />
                                    </div>
                                </Col>
                                <Col span={24}>                                        
                                    <Form.Item name='profileImage'>
                                        <div className='my-3 width-drag'>
                                            {
                                                editpatient ?
                                                <>
                                                    {geteditdata?.profileImage ? (
                                                        <Flex justify='center' style={{ position: 'relative', width: 150, margin: 'auto' }}>
                                                            <Image
                                                                src={geteditdata?.profileImage}
                                                                width={100}
                                                                height={100}
                                                                className='radius-12'
                                                            />
                                                            <div className='over-img'>
                                                                <ActionButton
                                                                    icon={<CloseCircleOutlined className='danger-color' />}
                                                                    onClick={handleDeleteProfileImage}
                                                                    type='ghost'
                                                                />
                                                            </div>
                                                        </Flex>
                                                    ) : (
                                                        <Dragger {...uploadProps} className="dragcs">
                                                            <p className="ant-upload-drag-icon">
                                                                <Image src='/assets/icons/upload.png' width={'41.6px'} preview={false} />
                                                            </p>
                                                            <p className="ant-upload-text fs-12 grayish">Upload Profile Picture</p>
                                                        </Dragger>
                                                    )}
                                                </>
                                                :
                                                <Dragger {...uploadProps} className="dragcs">
                                                    <p className="ant-upload-drag-icon">
                                                        <Image src='/assets/icons/upload.png' width={'41.6px'} preview={false} />
                                                    </p>
                                                    <p className="ant-upload-text fs-12 grayish">Upload Profile Picture</p>
                                                </Dragger>
                                            }
                                        </div>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12}>
                                    <HospiceInput
                                        name='firstName'
                                        label='First name'
                                        placeholder='Enter first name'
                                        required
                                        message='Please enter first name'
                                        value={form.getFieldValue("firstName") || ''}
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12}>
                                    <HospiceInput
                                        name='lastName'
                                        label='Last name'
                                        placeholder='Enter last name'
                                        required
                                        message='Please enter last name'
                                        value={form.getFieldValue("lastName") || ''}
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12}>
                                    <HospiceInput
                                        name='email'
                                        label='Email address'
                                        placeholder='Enter email address'
                                        required
                                        message='Please enter email address'
                                        value={form.getFieldValue("email") || ''}
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12}>
                                    <HospiceInput
                                        name='phoneNumber'
                                        label='Phone number'
                                        placeholder='Enter phone number'
                                        required
                                        message='Please enter phone number'
                                        value={form.getFieldValue("phoneNumber") || ''}
                                        type='number'
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12}>
                                    <Form.Item
                                        name='dob'
                                        label="Date of birth"
                                        placeholder='Enter date of birth'
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter Date of birth',
                                            },
                                        ]}
                                    >
                                        <DatePicker className='W-100' value={form.getFieldValue("dob") || ''}
                                            allowClear={false}
                                            inputReadOnly
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12}>
                                    <HospiceInput
                                        name='age'
                                        label='Age'
                                        placeholder='Enter age'
                                        required
                                        message='Please enter age'
                                        value={form.getFieldValue("age") || ''}
                                        type='number'
                                    />
                                </Col>
                                <Col span={24}>
                                    <HospiceInput
                                        name='address'
                                        label='Address'
                                        placeholder='Enter street address'
                                        required
                                        message='Please enter street address'
                                        value={form.getFieldValue("address") || ''}
                                    />
                                </Col>
                                <Col span={24}>
                                    <HospiceInput
                                        textArea
                                        name='note'
                                        label='Note'
                                        placeholder='Enter note'
                                        required
                                        message='Please enter note'
                                        value={form.getFieldValue("note") || ''}
                                    />
                                </Col>
                            </Row>
                        </Card>
                        <Card className='shadow-in border0 radius-12'>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <div className='mb-3'>
                                        <ModuleTopSmhead
                                            name='Caretaker Details'
                                        />
                                    </div>
                                </Col>

                                <Col xs={24} sm={24} md={24} lg={12}>
                                    <HospiceInput
                                        name='careTaker_firstName'
                                        label='First name'
                                        placeholder='Enter first name'
                                        required
                                        message='Please enter first name'
                                        value={form.getFieldValue("careTaker_firstName") || ''}
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12}>
                                    <HospiceInput
                                        name='careTaker_lastName'
                                        label='Last name'
                                        placeholder='Enter last name'
                                        required
                                        message='Please enter last name'
                                        value={form.getFieldValue("careTaker_lastName") || ''}
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12}>
                                    <HospiceInput
                                        name='careTaker_email'
                                        label='Email address'
                                        placeholder='Enter email address'
                                        required
                                        message='Please enter email address'
                                        value={form.getFieldValue("careTaker_email") ||  ''}
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12}>
                                    <HospiceInput
                                        name='careTaker_phoneNumber'
                                        label='Phone number'
                                        placeholder='Enter phone number'
                                        required
                                        message='Please enter phone number'
                                        value={form.getFieldValue("careTaker_phoneNumber") || ''}
                                        type='number'
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12}>
                                    <HospiceInput
                                        name='careTaker_relationship'
                                        label='Relationship'
                                        placeholder='Enter your relationship with patient'
                                        required
                                        message='Please enter your relationship with patient'
                                        value={form.getFieldValue("careTaker_relationship") || ''}
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12}>
                                    <HospiceInput
                                        name='careTaker_secondryNumber'
                                        label='Sec. Number'
                                        placeholder='Enter secondary phone number'
                                        required
                                        message='Please enter secondary phone number'
                                        value={form.getFieldValue("careTaker_secondryNumber") || ''}
                                        type='number'
                                    />
                                </Col>
                                <Col span={24}>
                                    <HospiceInput
                                        textArea
                                        name='careTaker_address'
                                        label='Address'
                                        placeholder='Enter street address'
                                        required
                                        message='Please enter street address'
                                        value={form.getFieldValue("careTaker_address") ||  ''}
                                    />
                                </Col>
                            </Row>
                        </Card>
                        <Card className='shadow-in border0 radius-12'>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <div className='mb-3'>
                                        <ModuleTopSmhead
                                            name='Medical Record'
                                        />
                                    </div>
                                </Col>
                                <Col span={24}>
                                    <Form.Item name='medicalReport' >
                                        <div className='my-3 width-drag'>
                                            <Dragger {...propMult} className="dragcs">
                                                <p className="ant-upload-drag-icon">
                                                    <Image src='/assets/icons/upload.png' width={'41.6px'} preview={false} />
                                                </p>
                                                <Typography.Text className="fs-12 grayish">Upload Medical Record</Typography.Text>
                                            </Dragger>
                                        </div>
                                        {
                                            editpatient &&
                                            <Space direction='vertical' size={10}>
                                                {
                                                    geteditdata?.medical_report?.map((data,index)=>
                                                        <div className='viewDownload space-between-align' key={index}>
                                                            <Typography.Text className='gray-text fs-12'>
                                                                {
                                                                    data?.name
                                                                }
                                                            </Typography.Text>
                                                            <ActionButton
                                                                title='Delete'
                                                                icon={<DeleteOutlined className='danger-color' />}
                                                                onClick={()=>{setDeletePatientDoc(true);setDeletePatMed({id:data?.id, name:data?.name})}}
                                                                type='ghost'
                                                            />
                                                        </div>
                                                    )
                                                }
                                            </Space>
                                            
                                        }
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                        <Card className='shadow-in border0 radius-12'>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <div className='mb-3'>
                                        <ModuleTopSmhead
                                            name='Identity Card'
                                        />
                                    </div>
                                </Col>
                                <Col span={24}>
                                    <Form.Item name='idCards' >
                                        <div className='my-3 width-drag'>
                                            <Dragger {...propMultlast} className="dragcs">
                                                <p className="ant-upload-drag-icon">
                                                    <Image src='/assets/icons/upload.png' width={'41.6px'} preview={false} />
                                                </p>
                                                <Typography.Text className="fs-12 grayish">Upload Identity Card</Typography.Text>
                                            </Dragger>
                                        </div>
                                        {
                                            editpatient &&
                                            <Space direction='vertical' size={10}>
                                                {
                                                    geteditdata?.id_cards?.map((data,index)=>
                                                        <div className='viewDownload space-between-align' key={index}>
                                                            <Typography.Text className='gray-text fs-12'>
                                                                {
                                                                    data?.name
                                                                }
                                                            </Typography.Text>
                                                            <ActionButton
                                                                title='Delete'
                                                                icon={<DeleteOutlined className='danger-color' />}
                                                                onClick={()=>{setDeletePatientDoc(true);setDeletePatId({id:data?.id, name:data?.name})}}
                                                                type='ghost'
                                                            />
                                                        </div>
                                                    )
                                                }
                                            </Space>
                                            
                                        }
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                        <Flex justify='end' align='center' gap={'small'}>
                            <Button
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={loading}
                            >
                                {
                                    editpatient? 'Update' : 'Save'
                                }
                            </Button>
                        </Flex>
                    </Space>
                </Form>
            </Drawer>

            <DeletePatientDocModal 
                deletepatientdoc={deletepatientdoc}
                deletepatid = {deletepatid}
                deletepatmed = {deletepatmed}
                EditPatientApi={EditPatientApi} 
                onClose={()=> { setDeletePatientDoc(false); setDeletePatId(null); setDeletePatMed(null);}}
                
            />
        </div>
    )
}

export { AddPatientDrawer }
