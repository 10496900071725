import React, { useEffect, useState } from 'react';
import { Button, Modal, Space, Form, Row, Col, Typography } from 'antd';
import { HospiceTimePicker } from '../../Forms';
import { apiCalls } from '../../../shared/Apis';
import { checkAuthorization, domainUrl } from '../../../shared';
import moment from 'moment';
import TimeField from '../TimeField';

const RescheduleAppointmentModal = ({ reschedulmodal, onClose, rescheduleid, getAddAppointmentDetail }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [appointmentDateIDs, setAppointmentDateIDs] = useState([]);
    const [dateTime, setDateTime] = useState(null);

    useEffect(() => {
        if (reschedulmodal && rescheduleid) {
            EditReschuleApi();
        } else {
            form.resetFields();
        }
    }, [reschedulmodal, rescheduleid]);

    const CreateRescheduleAppointment = async (formData) => {
        setLoading(true);
        try {
            const result = await apiCalls.appointmentApi.CreateRescheduleAppointment(formData);
            setLoading(false);
            getAddAppointmentDetail();
            if (result) onClose();
        } catch (error) {
            console.error("Error updating schedule data:", error);
        }
    };

    const onFinish = async () => {
        try {
            let formData = await form.validateFields();
            formData = {
                ...formData,
                id: rescheduleid,
                dates: formData?.dates?.map((timedates, index) => ({
                    ...timedates,
                    date: moment(timedates.date).format('YYYY-MM-DD'),
                    fromTime: moment(timedates.fromTime).format('LT'),
                    toTime: moment(timedates.toTime).format('LT'),
                    appointmentDateID: appointmentDateIDs[index],
                    selectUrl: index + 1
                }))
            };            
            CreateRescheduleAppointment(formData);
        } catch (error) {
            console.error("Form validation error:", error);
        }
    };

    const EditReschuleApi = () => {
        const { userToken } = checkAuthorization();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', userToken);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${domainUrl}/getAppointmentDates/${rescheduleid}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result?.success) {
                    const arraydates = result.data.map(data => ({
                        date: moment(data?.date, 'YYYY-MM-DD'),
                        fromTime: moment(data?.fromTime, 'HH:mm:ss'),
                        toTime: moment(data?.toTime, 'HH:mm:ss'),
                    }));
                    const ids = result.data.map(data => data.id);
                    const dateTm = result.data.map(data => data.dateTime);
                    form.setFieldsValue({ dates: arraydates });
                    setAppointmentDateIDs(ids);
                    setDateTime(dateTm);
                } else {
                    throw new Error('Failed to fetch appointment data');
                }
            })
            .catch(error => {
                console.error("Error fetching appointment data:", error);
                alert(error.message);
            });
    };

    return (
        <Modal
            width={500}
            className='shadow-c modal'
            title='Reschedule appointment'
            open={reschedulmodal}
            onOk={onClose}
            onCancel={onClose}
            centered
            footer={
                <Space style={{ justifyContent: "end", marginTop: '15px' }} className="w-100">
                    <Button onClick={onClose}>Cancel</Button>
                    <Button
                        block
                        type="primary"
                        htmlType='submit'
                        loading={loading}
                        onClick={() => form.submit()}
                    >
                        Send
                    </Button>
                </Space>
            }
        >
            <Form
                form={form}
                layout="vertical"
                className='pt-3'
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
            >
                <Row gutter={[16, 0]} align="middle">
                    <Col span={24}>
                        <TimeField {...{ form }} dateTime={dateTime} />
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export { RescheduleAppointmentModal };
