import { message } from "antd";
import { checkAuthorization } from "../../helpers";
import { domainUrl } from "../../constants";

const updateCreateApi = (formData,id) => {
    const {userToken} = checkAuthorization()
    var myHeaders = new Headers();
    myHeaders.append('Authorization', userToken);
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formData,
        redirect: 'follow'
    };
    const url =  id? '/updatePatient' : '/createPatient'
    return(
        fetch(domainUrl + url, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result?.success) {
                message.success(result?.message);
                return 1
            } else {
                message.error(result?.message);
                return 0
            }
        })
        .catch(error => {
            message.error(error.message || 'Something went wrong');
            return 0
        })
    )
};

const DeletePatientApi=(deleteitem)=>{
    const {userToken} = checkAuthorization()
    var myHeaders = new Headers();
    myHeaders.append("Content-Type","application/json")
    myHeaders.append('Authorization', userToken);
    var requestOptions = {
      method:'DELETE',
      headers: myHeaders,
      redirect:'follow'
    }
    return(
        fetch(domainUrl + `/deletePatient/${deleteitem}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result?.success) {
                message.success(result?.message)
            } 
            else 
                throw result?.message
        })
        .catch(error => {
            message.error(error.message || 'Something went wrong');
        })
    )
  }

  

  const DeletePatApiId=(deletepatid)=>{
    const {userToken} = checkAuthorization()
    var myHeaders = new Headers();
    myHeaders.append("Content-Type","application/json")
    myHeaders.append('Authorization', userToken);
    var requestOptions = {
      method:'DELETE',
      headers: myHeaders,
      redirect:'follow'
    }
    return(
        fetch(domainUrl + `/deletePatientIDCard/${deletepatid}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result?.success) {
                message.success(result?.message)
            } 
            else 
                throw result?.message
        })
        .catch(error => {
            message.error(error.message || 'Something went wrong');
        })
    )
}

const DeletePatApiMed=(deletepatmed)=>{
    const {userToken} = checkAuthorization()
    var myHeaders = new Headers();
    myHeaders.append("Content-Type","application/json")
    myHeaders.append('Authorization', userToken);
    var requestOptions = {
      method:'DELETE',
      headers: myHeaders,
      redirect:'follow'
    }
    return(
        fetch(domainUrl + `/deletePatientDoc/${deletepatmed}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result?.success) {
                message.success(result?.message)
            } 
            else 
                throw result?.message
        })
        .catch(error => {
            message.error(error.message || 'Something went wrong');
        })
    )
}


const patientApi = {
    updateCreateApi,
    DeletePatientApi,
    DeletePatApiId,
    DeletePatApiMed,
}

export {patientApi}
