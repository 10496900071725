import { Row, Col, Form, Space, Typography, Flex } from "antd";
import { HospiceTimePicker } from "../../Forms";
import { MinusCircleOutlined } from "@ant-design/icons";
import { useState } from "react";
import { ModuleTopHeadingBtn } from "../../PageComponents/ModuleTopHeadingBtn";
import moment from "moment";

const { Text } = Typography;

const TimeField = ({ form, dateTime = [], visible }) => {
  const [datetimeadd, setDateTimeAdd] = useState([]);

  const handleDateTimeChange = (field, value, name) => {
    setDateTimeAdd((prev) => ({
      ...prev,
      [name]: {
        ...prev[name],
        [field]: value,
      },
    }));
  };

  return (
    <Form.List name="dates">
      {(fields, { add, remove }) => (
        <Space direction="vertical" className="w-100 mb-3">
          <ModuleTopHeadingBtn name="Date" onClick={() => add()} />
          {fields.map(({ key, name }) => (
            <Row key={key} gutter={[16, 0]} align="middle">
              {!visible && (
                <Col span={24}>
                  {dateTime[key] && (
                    <Flex gap={4} className="my-3" vertical>
                      <Text>Appointment Created Date & Time</Text>
                      <Text>
                        <strong>
                          {moment(dateTime[key]).format('MMM Do YYYY - HH:mm:ss')}
                        </strong>
                      </Text>
                    </Flex>
                  )}
                </Col>
              )}
              <Col xs={24} sm={24} md={24} lg={8}>
                <HospiceTimePicker
                  datePicker
                  label="Date"
                  name={[name, 'date']}
                  required
                  message="Please select date"
                  value={datetimeadd[name]?.date}
                  onChange={(value) => handleDateTimeChange('date', value, name)}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={8}>
                <HospiceTimePicker
                  label="From time"
                  name={[name, 'fromTime']}
                  required
                  message="Please enter from time"
                  value={datetimeadd[name]?.fromTime}
                  onChange={(value) => handleDateTimeChange('fromTime', value, name)}
                />
              </Col>
              <Col xs={22} sm={22} md={11} lg={7}>
                <HospiceTimePicker
                  label="To time"
                  name={[name, 'toTime']}
                  required
                  message="Please enter to time"
                  value={datetimeadd[name]?.toTime}
                  onChange={(value) => handleDateTimeChange('toTime', value, name)}
                />
              </Col>
              <Col span={1} className="justify-end">
                <MinusCircleOutlined onClick={() => remove(name)} />
              </Col>
            </Row>
          ))}
        </Space>
      )}
    </Form.List>
  );
};

export default TimeField;
