import { patientApi } from './PatientApi'
import { employeeApi } from './EmployeeApi' 
import { appointmentApi } from './AddAppointment'
import { forgotpasswordApi } from './ForgotPasswordApi'
import { changepassApi } from './ChangePassApi'

const apiCalls={
    patientApi,
    employeeApi,
    appointmentApi,
    forgotpasswordApi,
    changepassApi,
}
export {apiCalls}