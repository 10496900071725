import React, { useEffect, useState } from 'react'
import { Image, Space, Row, Col, Form, Dropdown, Card, Table, Button, Flex, Typography } from 'antd'
import { MyInput, ModuleTopHeading, ActionButton, SendMsgModal, AlertModal, ViewPateintDrawer, AddPatientDrawer, PatientServiceViewDrawer } from '../../components';
import { DeleteOutlined, EditOutlined, EyeOutlined, FilterOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom/dist';
import { TableLoader, actionsApi } from '../../shared'
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

const { Text } = Typography

let timer

const Patients = ({removeadd}) => {

    const [form] = Form.useForm()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { addpatientDetail, loadingAddPatientDetail, filters, pagination } = useSelector(state => state?.addpatient)
    const [ visible, setVisible ] = useState(false)
    const [ visiblemodal, setVisibleModal] = useState(false)
    const [ deleteitem, setDeleteItem ] = useState(false)
    const [editpatient, setEditPatient] = useState(null)
    const [ viewonlypatient, setViewOnlyPatient ] = useState(false)
    const [ viewservice, setViewService ] = useState(false)
    const [ serviceid, setServiceId ] = useState(null)
    const [order, setOrder] = useState(1)

    const items = [
        {
            label: <a href="#">A-Z</a>,
            key: '0',
        },
        {
            label: <a href="#">Z-A</a>,
            key: '1',
        },
        {
            label: <a href="#">Most downloads</a>,
            key: '2',
        },
        {
            label: <a href="#">Less downloads</a>,
            key: '3',
        },
    ];
  
    useEffect(() => {
        dispatch(actionsApi?.getAddPatientDetail({ ...filters, ...pagination, pageNo: 1 }))
        form.setFieldsValue({
            ...filters,
        })
        setOrder(filters?.order)

    }, [])
    const filter = (order) => {
        let data = form.getFieldsValue()
        dispatch(actionsApi?.getAddPatientDetail({ ...data, order, ...pagination, pageNo: 1 }))
        dispatch(actionsApi?.changeAddpatientDetailFilters({ ...data, order }))
    }
    const call = (pageNo = 1, pageSize = 10) => {
        dispatch(actionsApi?.getAddPatientDetail({ ...filters, pageNo, pageSize }))
    }

    function searchHandler(name, pageNo=1, pageSize=20) {
        dispatch(actionsApi?.getAddPatientDetail({ ...filters, name, pageNo, pageSize }))
    }

    const onClick = ({ key }) => {
        key = parseInt(key) + 1
        setOrder(key)
        filter(key)
    }
    function debounce(func, delay) {
        return function(...args) {
            clearTimeout(timer)
            timer = setTimeout(() => {
                func.apply(this, args)
            }, delay)
        };
    }
    const debouncedSearchHandler = debounce(searchHandler, 400)
  
    const columns = [
        
        {
            title: 'Patient Name',
            dataIndex: 'firstName',
            render: (_,row)=> `${row?.firstName} ${row?.lastName}`
        },
        {
            title: 'Age',
            dataIndex: 'age',
        },
        {
            title: 'Disease',
            dataIndex: 'note',
        },
        {
            title: 'Time Stamp',
            dataIndex: 'created_at',
            render: (created_at) => (
                <Text>
                    {
                        moment(created_at).format('MMMM Do YYYY, h:mm:ss a')
                    }
                </Text>
            )
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (status) => {
                return (
                    status === 1 ? (
                        <Space align='center'>
                            <img src="/assets/icons/online.png" width={'12px'} alt="" />
                            <Text style={{transform:"translateY(-2px)",display:'block'}}>Active</Text>
                        </Space>
                    ) : (
                        <Space>
                            <img src="/assets/icons/offline.png" width={'12px'} alt="" />
                            <Text style={{transform:"translateY(-2px)",display:'block'}}>Inactive</Text>
                        </Space>
                    )
                );
            }
        },
        {
            title: 'Action',
            key: 'action',
            fixed: 'right',
            align:'right',
            width: 150,
            render: (_, row) => (
                <Space>
                    <ActionButton
                        title='View Patient'
                        icon={<EyeOutlined />}
                        onClick={() => {setViewOnlyPatient(true);setEditPatient(row?.id)}}
                        type='ghost'
                    />
                    <ActionButton
                        title='Edit Patient'
                        icon={<EditOutlined />}
                        onClick={() => { setVisible(true); setEditPatient(row?.id) }}
                        type='ghost'

                    />
                    <ActionButton
                        title='Services'
                        icon={<Image src='/assets/icons/briefcase.png' width={16} preview={false} />}
                        onClick={() => { setViewService(true); setServiceId(row?.id) }}
                        type='ghost'

                    />
                    <ActionButton
                        title='Delete Patient'
                        icon={<DeleteOutlined className='danger-color' />}
                        onClick={() => { setDeleteItem({id: row?.id, name:row?.firstName})}}
                        type='ghost'
                        danger
                    />
                </Space>
            ),
        },
    ];
    

  return (
    <div>
        <Card className='radius-12 border0'>
            <Row gutter={[12,12]}>
                <Col span={24}>
                    {
                        removeadd ?
                        <ModuleTopHeading
                            name='Patients'
                        />
                        :
                        <ModuleTopHeading
                            name='Patients'
                            title='Add patient'
                            onClick={()=>{setVisible(true)}}
                        />
                    }
                </Col>
                <Col  span={24}>
                    <Form
                        form={form}
                        layout="vertical" 
                    >
                        <Flex gap={'small'} className='w-100' align='end'>
                            <MyInput
                                name='firstName'
                                label='Search'
                                placeholder='Search'
                                value={form.getFieldValue("firstName") || ''} 
                                className='w-100'
                                onChange={(e)=> debouncedSearchHandler(e.target.value, 1, 20)}
                            />
                            <Dropdown
                                menu={{
                                    items,
                                    onClick
                                }}
                                trigger={['click']}
                                arrow
                                icon={<FilterOutlined />}
                                className='margin-top'
                            >
                                <Button
                                    icon={<FilterOutlined />}
                                >
                                    Filter
                                </Button>
                            </Dropdown>
                        </Flex>
                    </Form>
                </Col>
                <Col span={24}>
                 <Table 
                        size='large'
                        columns={columns} 
                        dataSource={addpatientDetail}
                        scroll={{x: 1000,}}
                        pagination={{
                            hideOnSinglePage: true,
                            total: pagination?.totalRecords,
                            pageSize: pagination?.pageSize,
                            defaultPageSize: pagination?.pageSize,
                            current: pagination?.pageNo,
                            size: "default",
                            pageSizeOptions: ['10', '20', '50', '100'],
                            onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                            showTotal: (total) => <Button>Total: {total}</Button>,
                        }}
                        loading={
                            {
                                ...TableLoader,
                                spinning: loadingAddPatientDetail
                            }
                        }
                    />
                </Col>
            </Row>
        </Card>
        <ViewPateintDrawer
            viewonlypatient={viewonlypatient}
            editpatient={editpatient}
            onClose={()=>{setViewOnlyPatient(false);setEditPatient(null)}}
        />

        <PatientServiceViewDrawer 
            viewservice={viewservice}
            serviceid={serviceid}
            onClose={()=>{setViewService(false);setServiceId(null)}}
        />

        <AddPatientDrawer 
            visible={visible}
            editpatient={editpatient}
            getAddPatientDetail={call}
            onClose={()=>{setVisible(false);setEditPatient(null)}}
        />

        <SendMsgModal 
            visiblemodal={visiblemodal}
            onClose={()=>setVisibleModal(false)}
        />
        <AlertModal 
            deleteitem={deleteitem}
            getAddPatientDetail={call}
            onClose={()=>setDeleteItem(false)}
        />
    </div>
  )
}

export {Patients}