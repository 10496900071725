import React, { useState } from 'react'
import { Button, Col, Flex, Form, Image, message, Row, Typography } from 'antd'
import { HospiceInput } from '../../input'
import { apiCalls } from '../../../shared/Apis'
import { useNavigate } from 'react-router-dom'

const { Title } = Typography

const NewCredential = ({otpid}) => {
    const [form] = Form.useForm()
    const [ loading, setLoading ] = useState(false)
    const navigate = useNavigate()

    const ResetPassword = async (formData) => {
        setLoading(true);
        try {
            const result = await apiCalls?.forgotpasswordApi.ResetPassword({...formData,id:otpid});
            setLoading(false);
            if (result) {
                form.resetFields();
                navigate('/successscreen')
            }
        } catch (error) {
            message.error(error);
            setLoading(false);
        }
    }

    const onFinish = async () => {
        try {
            const formData = await form.validateFields();
            ResetPassword(formData);
        } catch (error) {
            console.error("Form validation error:", error);
        }
    };

  return (
    <div className='login-form-cover' >
        <Flex justify="center">
            <Row className="login-form border-grey" justify={"center"}>
                <Col xs={24} sm={24} md={14}>
                    <Flex vertical align="center" gap={'large'} justify="center" className="height-100">
                        <Flex vertical align="center" justify="center">
                            <Image
                                src={"./assets/logo.png"}
                                alt='Store App Admin Panel'
                                preview= {false}
                                width={'150px'}
                            />
                            <Title 
                                level={5} 
                                className="text-center brand-color fs-15"
                                >
                                New Credentials
                            </Title>
                        </Flex>
                        <Form
                            form={form}
                            layout="vertical"
                            initialValues={{
                                remember: true,
                            }}
                            style={{ width: '100%' }}
                            onFinish={onFinish}
                        >
                            <Row>
                                <Col span={24}>
                                    <HospiceInput
                                        name='newPassword'
                                        type='password'
                                        label='New Password'
                                        size='large'
                                        placeholder='Enter your new password'
                                        required
                                        message={()=>{}}
                                        value={form.getFieldValue("newPassword") || ''} 
                                        className='w-100'
                                        rules={[
                                            {
                                                required: true,
                                            }
                                        ]}
                                        validator={({ getFieldValue }) => ({
                                            validator: (_, value) => {
                                                const reg = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*\d).{8,}$/;
                                                if (!reg.test(value)) {
                                                    return Promise.reject(new Error('Password should contain at least 8 characters, one uppercase letter, one number, one special character'));
                                                } else {
                                                    return Promise.resolve();
                                                }
                                            }
                                        })}
                                    />
                                </Col>
                                <Col span={24}>
                                    <HospiceInput
                                        name='confirmPassword'
                                        type='password'
                                        size='large'
                                        label='Confirm Password'
                                        placeholder='Enter your confirm password'
                                        dependencies={['newPassword']}
                                        required
                                        message='Please enter confirm password'
                                        value={form.getFieldValue("confirmPassword") || ''} 
                                        className='w-100'
                                        rules={[
                                            {
                                                required: true,
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue('newPassword') === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error('The password that you entered do not match!'));
                                                },
                                            }),
                                        ]}
                                        validator={({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('newPassword') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('The password that you entered do not match!'));
                                            },
                                        })}
                                    />
                                </Col>
                                <Col span={24}>
                                    <Flex justify='center'>
                                        <Button 
                                            type="primary"
                                            htmlType="submit" 
                                            loading={loading}
                                            >
                                            Submit
                                        </Button>
                                    </Flex>
                                </Col>
                            </Row>
                        </Form>
                    </Flex>
                </Col>
            </Row>
        </Flex>
    </div>
  )
}

export {NewCredential}