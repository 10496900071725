import React, { useEffect, useState } from 'react'
import { Space, Row, Col, Form, Typography, Card, Drawer, Avatar, Flex, message } from 'antd'
import { HospiceInput, ModuleTopSmhead, ActionButton } from '..';
import { CloseOutlined, DownloadOutlined } from '@ant-design/icons';
import { checkAuthorization, domainUrl } from '../../shared';

const ViewPateintDrawer = ({viewonlypatient, onClose, editpatient}) => {

    const [form] = Form.useForm()
    const [ singledata, setSingleData ] = useState(null) 
    const [ caretakerdata, setCareTakerData ] = useState(null)
    

    useEffect(()=>{
        if(editpatient){
            SingleView()
        }
    },[editpatient])
   
    const SingleView=()=>{
        const {userToken} = checkAuthorization()
        var myHeaders = new Headers();
        myHeaders.append("Content-Type","application/json")
        myHeaders.append('Authorization', userToken);
        var requestOptions = {
            method:'GET',
            headers: myHeaders,
            redirect:'follow'
        }
        return(
            fetch(domainUrl + `/viewPatient/${editpatient}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result?.data)
                if (result?.success) {
                    setSingleData(result?.data)
                    form.setFieldsValue(result?.data)
                    setCareTakerData(result?.data?.care_taker)                    
                } 
                else 
                    throw result?.message
            })
            .catch(error => {
                message.error(error.message || 'Something went wrong');
            })
        )
    }

    const downloadfile = (path,filename)=> {
        const url = document.createElement("a");
        url.href = path;
        url.setAttribute('download',filename);
        document.body.appendChild(url)
        url.click();
        document.body.removeChild(url)
    }

  return (
    <div>
        <Drawer
            onClose={onClose}
            open={viewonlypatient}
            width={1000}
            footer={false}
            closeIcon={false}
        >
            <Form
                    form={form}
                    layout="vertical"
                    initialValues={{
                        remember: true,
                    }}
                    // onFinish={onFinish}
                >
                    <Space direction='vertical' size={15} className='w-100'>
                        <div className='w-100 space-between'>
                            <Flex gap={'small'}>
                                <Avatar
                                    size={50}
                                    icon={<img src={singledata?.profileImage} width={120} />}
                                />
                                <div>
                                    <Typography.Title level={5} className='m-0'>
                                        {
                                            singledata?.firstName
                                        }
                                    </Typography.Title>
                                    <Typography.Text className='grayish fs-12'>
                                        Patient
                                    </Typography.Text>
                                </div>
                            </Flex>
                            <ActionButton
                                icon={<CloseOutlined />}
                                onClick={onClose}
                                type='ghost'
                            />
                        </div>
                        <Card className='shadow-in border0 radius-12'> 
                            <Row gutter={16}>
                                <Col span={24}>
                                    <div className='mb-3'>
                                        <ModuleTopSmhead
                                            name='Personal Details'
                                        />
                                    </div>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12}>
                                    <HospiceInput
                                        name='firstName'
                                        label='First name'
                                        value={form.getFieldValue("firstName") || ''} 
                                        disabled
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12}>
                                    <HospiceInput
                                        name='lastName'
                                        label='Last name'
                                        value={form.getFieldValue("lastName") || ''} 
                                        disabled
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12}>
                                    <HospiceInput
                                        name='note'
                                        label='Disease'
                                        value={form.getFieldValue("note") || ''} 
                                        disabled
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12}>
                                    <HospiceInput
                                        name='phoneNumber'
                                        label='Phone no'
                                        value={form.getFieldValue("phoneNumber") || ''} 
                                        disabled
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12}>
                                    <HospiceInput
                                        name='dob'
                                        label='Date of birth'
                                        value={form.getFieldValue("dob") || ''} 
                                        disabled
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12}>
                                    <HospiceInput
                                        name='age'
                                        label='Age'
                                        value={form.getFieldValue("age") || ''}
                                        disabled 
                                    />
                                </Col>
                                <Col span={24}>
                                    <HospiceInput
                                        textArea
                                        name='address'
                                        label='Address'
                                        value={form.getFieldValue("address") || ''}
                                        disabled
                                    />
                                </Col>
                            </Row>
                        </Card>
                        <Card className='shadow-in border0 radius-12'> 
                            <Row gutter={16}>
                                <Col span={24}>
                                    <div className='mb-3'>
                                        <ModuleTopSmhead
                                            name='Caretaker Details'
                                        />
                                    </div>
                                </Col>
                                
                                <Col xs={24} sm={24} md={24} lg={12}>
                                    <HospiceInput
                                        label='First name'
                                        value={caretakerdata?.firstName}
                                        disabled 
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12}>
                                    <HospiceInput
                                        label='Last name'
                                        value={caretakerdata?.lastName}
                                        disabled
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12}>
                                    <HospiceInput
                                        label='Email address'
                                        value={caretakerdata?.email}
                                        disabled
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12}>
                                    <HospiceInput
                                        label='Phone number'
                                        value={caretakerdata?.phoneNumber}
                                        disabled
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12}>
                                    <HospiceInput
                                        label='Relationship'
                                        value={caretakerdata?.relationship}
                                        disabled
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12}>
                                    <HospiceInput
                                        label='Sec. Number'
                                        value={caretakerdata?.secondryNumber}
                                        disabled
                                    />
                                </Col>
                                <Col span={24}>
                                    <HospiceInput
                                        textArea
                                        label='Address'
                                        value={caretakerdata?.address}
                                        disabled
                                    />
                                </Col>
                            </Row>
                        </Card>
                        <Card className='shadow-in border0 radius-12'> 
                            <Row gutter={16}>
                                <Col span={24}>
                                    <div className='mb-3'>
                                        <ModuleTopSmhead
                                            name='Medical Record'
                                        />
                                    </div>
                                </Col>
                                <Col xs={24} sm={24} md={12}>
                                    <Space direction='vertical' size={10} className='w-100'>
                                        {
                                            singledata?.medical_report?.map((report,index) =>
                                                <div className='viewDownload space-between-align' key={index}>
                                                    <Typography.Text className='gray-text fs-12'>
                                                        {report?.name}
                                                    </Typography.Text>
                                                    <ActionButton
                                                        title='Download'
                                                        icon={<DownloadOutlined />}
                                                        onClick={() => downloadfile(report?.path,report?.name)}
                                                        type='ghost'
                                                    />
                                                </div>
                                            )
                                        }
                                    </Space>
                                </Col>
                            </Row>
                        </Card>
                        <Card className='shadow-in border0 radius-12'> 
                            <Row gutter={16}>
                                <Col span={24}>
                                    <div className='mb-3'>
                                        <ModuleTopSmhead
                                            name='Identity Card'
                                        />
                                    </div>
                                </Col>
                                <Col xs={24} sm={24} md={12}>
                                    <Space direction='vertical' size={10} className='w-100'>
                                        {
                                            singledata?.id_cards?.map((idcards,index)=>
                                                <div className='viewDownload space-between-align' key={index}>
                                                    <Typography.Text className='gray-text fs-12'>
                                                        {idcards?.name}
                                                    </Typography.Text>
                                                    <ActionButton
                                                        title='Download'
                                                        icon={<DownloadOutlined />}
                                                        onClick={() => downloadfile(idcards?.path,idcards?.name)}
                                                        type='ghost'
                                                    />
                                                </div>
                                            )
                                        }
                                    </Space>
                                </Col>
                            </Row>
                        </Card>
                    </Space>
                </Form>
        </Drawer>
    </div>
  )
}

export {ViewPateintDrawer}