import React, { useState } from 'react'
import { Card, Col, Row, Typography, Space, Image, Form, Table, Flex, Button} from 'antd'
import { ActionButton, AlertModal, ModuleTopHeading, MyInput } from '../../components'
import {
    DeleteOutlined,
    EditOutlined,
    EyeOutlined,
} from '@ant-design/icons'
const { Text } = Typography


const AppTable = () => {
    const [form] = Form.useForm()
    const [ deleteitem, setDeleteItem ] = useState(false)

    const items = [
        {
            label: <a href="#">A-Z</a>,
            key: '0',
        },
        {
            label: <a href="#">Z-A</a>,
            key: '1',
        },
        {
            label: <a href="#">Registration date</a>,
            key: '2',
        },
    ];
    

    const columns = [
        {
            title: 'Patient ID',
            dataIndex: 'patientid',
            key: 'patientid',
            fixed: 'left',
        },
        {
            title: 'Patient Name',
            dataIndex: 'pName',
            key: 'pName',
        },
        {
            title: 'Age',
            dataIndex: 'age',
            key: 'age',
        },
        {
            title:  'Disease',
            dataIndex: 'disease',
            key: 'disease',
        },
        {
            title: 'Time Stamp',
            dataIndex: 'timestamp',
            key: 'timestamp',
        },
        {
            title: 'Action',
            key: 'action',
            fixed: 'right',
            align:'right',
            width: 150,
            render: (_, row) => (
                <Space>
                    <ActionButton
                        title='View Item'
                        icon={<EyeOutlined />}
                        // onClick={() => { setVisible(true); setEditBox(row?.id) }}
                        type='ghost'

                    />
                    <ActionButton
                        title='Edit Box'
                        icon={<EditOutlined />}
                        // onClick={() => { setVisible(true); setEditBox(row?.id) }}
                        type='ghost'

                    />
                    <ActionButton
                        title='Add Prescription'
                        icon={<Image src='/assets/icons/briefcase.png' width={16} preview={false} />}
                        // onClick={() => { setVisible(true); setEditBox(row?.id) }}
                        type='ghost'

                    />
                    <ActionButton
                        title='Delete Box'
                        icon={<DeleteOutlined className='danger-color' />}
                        onClick={() => { setDeleteItem({name:row?.pName})}}
                        type='ghost'
                        danger
                    />
                </Space>
            ),
        },
    ];
      const data = [
        {
            key: '1',
            patientid: '#1',
            pName: 'Sara Khan',
            age: '27 years',
            disease: 'Lorem Ispsum',
            timestamp: '11/02/2022 23:05'
        },
        {
            key: '2',
            patientid: '#2',
            pName: 'Zainab Shabir',
            age: '28 years',
            disease: 'Lorem Ispsum',
            timestamp: '11/02/2022 23:05'
        },
        {
            key: '3',
            patientid: '#3',
            pName: 'Zarnab Obaid',
            age: '24 years',
            disease: 'Lorem Ispsum',
            timestamp: '11/02/2022 23:05'
        },
        {
            key: '4',
            patientid: '#4',
            pName: 'Noor ul Hassan',
            age: '29 years',
            disease: 'Lorem Ispsum',
            timestamp: '11/02/2022 23:05'
        },
        {
            key: '5',
            patientid: '#5',
            pName: 'Maryam Tanveer',
            age: '20 years',
            disease: 'Lorem Ispsum',
            timestamp: '11/02/2022 23:05'
        },
      ];
    
  return (
    <div>
        <Card className='radius-12 border0 shadow-c'>
            <Row gutter={[24,24]}>
                <Col span={24}>
                    <Flex justify='space-between'>
                        <ModuleTopHeading 
                            name='Patients Overview'
                        />
                        <a href="#">View All</a>
                    </Flex>
                </Col>
                <Col span={24}>
                    <Form
                        form={form}
                        layout="vertical" 
                    >
                        <Flex gap={'small'} className='w-100' align='end'>
                            <MyInput
                                name='search'
                                label='Search'
                                placeholder='Search'
                                value={form.getFieldValue("search") || ''} 
                                className='w-100'
                            />
                        </Flex>
                    </Form>
                </Col>
                <Col span={24}>
                    <Table 
                        size='large'
                        columns={columns} 
                        dataSource={data}
                        scroll={{x: 1000,}}
                        pagination={{ 
                            hideOnSinglePage: true, 
                            total: 12,
                            // total: pagination?.totalRecords,
                            // pageSize: pagination?.pageSize,
                            // defaultPageSize: pagination?.pageSize,
                            // current: pagination?.pageNo,
                            size: "default",
                            pageSizeOptions:['20', '50', '100'],
                            //onChange: (pageNo, pageSize)=> call(pageNo, pageSize),
                            showTotal: total => <Button>Total: {total}</Button>
                        }}
                    />
                </Col>
            </Row>
        </Card>
        <AlertModal 
            deleteitem={deleteitem}
            onClose={()=>setDeleteItem(false)}
        />
    </div>
  )
}

export {AppTable}