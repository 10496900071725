import { message } from "antd";
import { checkAuthorization } from "../../helpers";
import { domainUrl } from "../../constants";

const updateCreateApiEmployee = (formData,id) => {
    const {userToken} = checkAuthorization()
    var myHeaders = new Headers();
    myHeaders.append('Authorization', userToken);
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formData,
        redirect: 'follow'
    };
    const url = id ? '/updateEmployee' : '/createEmployee'
    return(
        fetch(domainUrl + url, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result?.success) {
                message.success(result?.message);
                return 1
            } else {
                message.error(result?.message);
                return 0
            }
        })
        .catch(error => {
            message.error(error.message || 'Something went wrong');
            return 0
        })
    )
};

const getRoleapi= ()=>{
    const {userToken} = checkAuthorization()
    var myHeaders = new Headers()
    myHeaders.append('Authorization', userToken)
    return (
        fetch(domainUrl + "/selectRole", {
            method: "GET",
            headers: myHeaders
        })
        .then(response => response.json())
        .then(result => {
            
            console.log(result?.data)
            if (result?.success) 
                return result?.data
            else
                throw result?.message
        })
        .catch(error => {
            message.error(error)
            return 0
        })
    )
}



const DeleteEmployeeApi=(deleteid)=>{
    const {userToken} = checkAuthorization()
    var myHeaders = new Headers();
    myHeaders.append("Content-Type","application/json")
    myHeaders.append('Authorization', userToken);
    var requestOptions = {
      method:'DELETE',
      headers: myHeaders,
      redirect:'follow'
    }
    return(
        fetch(domainUrl + `/deleteEmployee/${deleteid}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result?.success) {
                message.success(result?.message)
            } 
            else 
                throw result?.message
        })
        .catch(error => {
            message.error(error.message || 'Something went wrong');
        })
    )
}

const DeleteEmpEdApiidcard=(idcardsdelete)=>{
    const {userToken} = checkAuthorization()
    var myHeaders = new Headers();
    myHeaders.append("Content-Type","application/json")
    myHeaders.append('Authorization', userToken);
    var requestOptions = {
      method:'DELETE',
      headers: myHeaders,
      redirect:'follow'
    }
    return(
        fetch(domainUrl + `/deleteEmpIDCard/${idcardsdelete}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result?.success) {
                message.success(result?.message)
            } 
            else 
                throw result?.message
        })
        .catch(error => {
            message.error(error.message || 'Something went wrong');
        })
    )
}

const DeleteEmpEdApiEdu=(edudocdelete)=>{
    const {userToken} = checkAuthorization()
    var myHeaders = new Headers();
    myHeaders.append("Content-Type","application/json")
    myHeaders.append('Authorization', userToken);
    var requestOptions = {
      method:'DELETE',
      headers: myHeaders,
      redirect:'follow'
    }
    return(
        fetch(domainUrl + `/deleteEmpEduDoc/${edudocdelete}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result?.success) {
                message.success(result?.message)
            } 
            else 
                throw result?.message
        })
        .catch(error => {
            message.error(error.message || 'Something went wrong');
        })
    )
}
 

  


const employeeApi = {
    updateCreateApiEmployee,
    DeleteEmployeeApi,
    getRoleapi,
    DeleteEmpEdApiidcard,
    DeleteEmpEdApiEdu,
}

export {employeeApi}
