import { configureStore } from "@reduxjs/toolkit";
import {
	login,
	addpatient,
	employee,
	appointment,
	cardapi,
	chartapi,
	dashboard,
	servicepatient,
	viewmedicineprescription,
	visitapi,
	visitapisingle,
} from "./action"
const store = configureStore({
	reducer: {
		login,
		addpatient,
		employee,
		appointment,
		cardapi,
		chartapi,
		dashboard,
		servicepatient,
		viewmedicineprescription,
		visitapi,
		visitapisingle,
	},
})
export default store