import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Flex, Image, Row, Typography } from 'antd';
import './index.css';
import { ModuleTopHeading } from '../PageComponents';
import { useNavigate } from 'react-router-dom';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { CalendarEvents } from './CalendarEvents';
import { MySelect } from '../Forms';
import { apiCalls } from '../../shared/Apis';
import { checkAuthorization, domainUrl } from '../../shared';

const localizer = momentLocalizer(moment);

const { Text } = Typography;

const PlannerComponents = (props) => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [rolelookup, setRoleLookUp] = useState([]);
    const [staffdata, setStaffData] = useState([]);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [events, setEvents] = useState([]);

    useEffect(() => {
        getRoleapi();
    }, []);

    const getRoleapi = async () => {
        let result= await apiCalls?.employeeApi?.getRoleapi()
        if(result)
        setRoleLookUp(result)
    }

    const GetStaffView = (roleID) => {
        const { userToken } = checkAuthorization();
        var myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('Authorization', userToken);
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(domainUrl + `/getStaffByRoleID/${roleID}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result?.success) {
                    setStaffData(result.data);
                } else {
                    throw new Error('Failed to fetch employee data');
                }
            })
            .catch(error => {
                console.error('Error fetching staff:', error);
            });
    };

    useEffect(() => {
        fetchEvents(currentDate);
    }, [currentDate]);

    const fetchEvents = (date) => {
        const { userToken } = checkAuthorization();
        const currentMonth = moment(date).month() + 1;
        var myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('Authorization', userToken);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({ month: currentMonth }),
        };
    
        fetch(domainUrl + `/getCurrentMonthAppointment`, requestOptions)
            .then(response => response.json())
            .then(result => {
                const data = result?.data;
                if (data && typeof data === 'object') {
                    const formattedEvents = Object.keys(data).flatMap(date => {
                        return data[date].map(event => ({
                            start: new Date(date),
                            end: new Date(date), 
                            title: event.title,   
                            ...event               
                        }));
                    });
                    setEvents(formattedEvents);
                    console.log(formattedEvents);
                } else {
                    throw new Error('Failed to fetch events');
                }
            })
            .catch(error => {
                console.error('Error fetching events:', error);
            });
    };
    

    const handleViewChange = (date) => {
        setCurrentDate(date);
    };

    return (
        <div>
            <Row gutter={[24, 24]}>
                <Col xs={24} sm={24} md={24} lg={6} xl={5}>
                    <Card className='height-100'>
                        <Form form={form} layout='vertical'>
                            <MySelect
                                name="name"
                                value={form.getFieldValue('name') || ''}
                                options={rolelookup}
                                placeholder='Select role'
                                onChange={(value) => {
                                    const selectedRole = rolelookup.find(role => role.name === value);
                                    GetStaffView(selectedRole.id);
                                }}
                            />
                        </Form>
                        <Flex className='pt-3' vertical gap={10}>
                            {staffdata.map((data, index) => (
                                <Flex align='center' gap={'small'} key={index}>
                                    <Image
                                        src={data.profileImage}
                                        width={'40px'}
                                        height={'40px'}
                                        preview={false}
                                        className='radius-12'
                                        alt=""
                                    />
                                    <Text strong className='m-0 grayish'>
                                        {data.firstName + " " + data?.lastName}
                                    </Text>
                                </Flex>
                            ))}
                        </Flex>
                    </Card>
                </Col>
                {/* <CalendarEvents events={events} /> */}
                <Col xs={24} sm={24} md={24} lg={18} xl={19}>
                    <Card>
                        <Flex vertical gap={'large'}>
                            <ModuleTopHeading
                                name='Add appointment'
                                onClick={() => navigate('/appointment')}
                            />
                            <Calendar
                                localizer={localizer}
                                events={events}
                                date={currentDate}
                                defaultDate={currentDate}
                                startAccessor="start"
                                endAccessor="end"
                                style={{ height: 500 }}
                                views={['month', 'week', 'day']}
                                components={{
                                    event: CalendarEvents
                                }}
                                formats={{
                                    monthHeaderFormat: (date) => moment(date).format('DD MMMM YYYY'),
                                }}
                                onNavigate={handleViewChange}
                            />
                        </Flex>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export { PlannerComponents };
