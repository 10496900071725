import React, { useEffect, useState } from 'react'
import { Button, Col, Dropdown, Flex, Form, Row, Table, Typography } from 'antd'
import { ActionButton, ModuleTopHeading } from '../../../components/PageComponents'
import { MyInput } from '../../../components/Forms'
import { EyeOutlined, FilterOutlined } from '@ant-design/icons'
import { VisitChaplinModal } from '../../Modals/VisitChaplinModal'
import { actionsApi, TableLoader } from '../../../shared'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

const { Text } = Typography

let timer

const PatientVisitTable = ({serviceid}) => {

    const [form] = Form.useForm()
    const [ visible, setVisible ] = useState(false)

    const dispatch = useDispatch();
    const { visitviewDetail, loadingVisitviewDetail, filters, pagination } = useSelector(state => state?.visitapi);
    const [order, setOrder] = useState(1);
    const [ getvisitid, setGetVisitId ] = useState(null)

    const items = [
        { label: <a href="#">A-Z</a>, key: '0' },
        { label: <a href="#">Z-A</a>, key: '1' },
    ];

    useEffect(() => {
        if (serviceid) {
        dispatch(actionsApi?.getVisitDetail({ ...filters, ...pagination, pageNo: 1, id: serviceid }));
        form.setFieldsValue({ ...filters });
        setOrder(filters?.order);
        }
    }, [serviceid]);

    const filter = (order) => {
        let data = form.getFieldsValue();
        dispatch(actionsApi?.getVisitDetail({ ...data, order, ...pagination, pageNo: 1, id: serviceid }));
        dispatch(actionsApi?.changeVisitViewDetailFilters({ ...data, order }));
    };

    const call = (pageNo = 1, pageSize = 10) => {
        dispatch(actionsApi?.getVisitDetail({ ...filters, pageNo, pageSize, id: serviceid }))
    }

    function searchHandler(name, pageNo=1, pageSize=20) {
        const newFilters = { ...filters, name, pageNo, pageSize, id: serviceid };
        dispatch(actionsApi?.getVisitDetail(newFilters));
        dispatch(actionsApi?.changeVisitViewDetailFilters(newFilters));
    }

    


    const onClick = ({ key }) => {
        key = parseInt(key) + 1;
        setOrder(key);
        filter(key);
    };

    function debounce(func, delay) {
        return function(...args) {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, args);
        }, delay);
        };
    }

    const debouncedSearchHandler = debounce(searchHandler, 400);
  
  
    const columns = [
        {
            title: 'User Name',
            dataIndex: 'firstName',
            render: (_,row)=> `${row?.firstName} ${row?.lastName}`
        },

        {
            title: 'Role',
            dataIndex: 'role',
        },

        {
            title: 'Visit Date',
            dataIndex: 'dateTime',
            render: (dateTime) => (
                <Text>
                    {
                        moment(dateTime).format('MMMM Do YYYY, h:mm:ss a')
                    }
                </Text>
            )
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (status) => {
                return (
                    status === 1 ? (
                        <Text >Visited</Text>
                    ) : (
                        <Text className='brand-color'>Unvisited</Text>
                    )
                );
            }
        },
        {
            title: 'Action',
            key: 'action',
            fixed: 'right',
            align:'right',
            width: 100,
            render: (_, row) => (
                <ActionButton
                    title='View Item'
                    icon={<EyeOutlined />}
                    onClick={() => {setVisible(true);setGetVisitId({
                        id:row?.id,
                        name:row?.firstName+' '+row?.lastName, 
                        date:row?.dateTime
                    })}}
                    type='ghost'
                />
            ),
        },
    ];

  return (
    <Row gutter={[12,12]}>
        <Col span={14}>
            <ModuleTopHeading
                name='Visits'
            />
        </Col>
        <Col  span={24}>
            <Form
                form={form}
                layout="vertical" 
            >
                <Flex gap={'small'} className='w-100' align='end'>
                    <MyInput
                        name='name'
                        label='Search'
                        placeholder='Search'
                        value={form.getFieldValue("name") || ''} 
                        className='w-100'
                        onChange={(e) => debouncedSearchHandler(e.target.value, 1, 20)}
                    />
                    <Dropdown
                        menu={{
                            items,
                            onClick
                        }}
                        trigger={['click']}
                        arrow
                        icon={<FilterOutlined />}
                        className='margin-top'
                    >
                        <Button
                            icon={<FilterOutlined />}
                        >
                            Filter
                        </Button>
                    </Dropdown>
                </Flex>
            </Form>
        </Col>
        <Col span={24}>
            <Table
            size='large'
            columns={columns}
            dataSource={visitviewDetail}
            scroll={{ x: 1000 }}
            pagination={{
                hideOnSinglePage: true,
                total: pagination?.totalRecords,
                pageSize: pagination?.pageSize,
                defaultPageSize: pagination?.pageSize,
                current: pagination?.pageNo,
                size: "default",
                pageSizeOptions: ['10', '20', '50', '100'],
                onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                showTotal: (total) => <Button>Total: {total}</Button>,
            }}
            loading={{ ...TableLoader, spinning: loadingVisitviewDetail }}
            />
        </Col>
        <VisitChaplinModal 
            visible={visible}
            getvisitid={getvisitid}
            onClose={()=> {setVisible(false);setGetVisitId(null)}}
        />
    </Row>
  )
}

export {PatientVisitTable}