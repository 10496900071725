import { message } from "antd";
import { checkAuthorization } from "../../helpers";
import { domainUrl } from "../../constants";

const SendEmailForgot = (formData) => {
    const {userToken} = checkAuthorization()
    var myHeaders = new Headers();
    myHeaders.append('Authorization', userToken);
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(formData),
        redirect: 'follow'
    };
    return(
        fetch(domainUrl + '/forgotPassword', requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result?.success) {
                message.success(result?.message);
                return 1
            } else {
                message.error(result?.message);
                return 0
            }
        })
        .catch(error => {
            message.error(error.message || 'Something went wrong');
            return 0
        })
    )
};
  

const OtpVerify = (formData) => {
    const {userToken} = checkAuthorization()
    var myHeaders = new Headers();
    myHeaders.append('Authorization', userToken);
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(formData),
        redirect: 'follow'
    };
    return(
        fetch(domainUrl + '/otpVerification', requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result?.success) {
                message.success(result?.message);
                return result
            } else {
                message.error(result?.message);
                return 0
            }
        })
        .catch(error => {
            message.error(error.message || 'Something went wrong');
            return 0
        })
    )
};

const ResetPassword = (formData) => {
    const {userToken} = checkAuthorization()
    var myHeaders = new Headers();
    myHeaders.append('Authorization', userToken);
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(formData),
        redirect: 'follow'
    };
    return(
        fetch(domainUrl + '/resetPassword', requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result?.success) {
                message.success(result?.message);
                return 1
            } else {
                message.error(result?.message);
                return 0
            }
        })
        .catch(error => {
            message.error(error.message || 'Something went wrong');
            return 0
        })
    )
};



const forgotpasswordApi = {
    SendEmailForgot,
    OtpVerify,
    ResetPassword,
}

export {forgotpasswordApi}
