import React, { useState, useEffect } from 'react';
import { Card, Col, Row, Radio, Space, Dropdown, Button } from 'antd';
import { ModuleTopHeading } from '../../components';
import { actionsApi } from '../../shared';
import moment from 'moment';
import { DownOutlined } from '@ant-design/icons';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarChartComponent = () => {
    const dispatch = useDispatch();
    const [value, setValue] = useState(1);
    const selectedYear = moment().year();
    const { addchartDetail, filters } = useSelector((state) => state?.chartapi);

    const filter = (status) => {
        dispatch(actionsApi?.getChartapiDetail({ year: selectedYear, status }));
        dispatch(actionsApi?.changeAddChartDetailFilters({ ...filters, status }));
    };

    const onChange = (e) => {
        const status = e.target.value;
        setValue(status);
        filter(status);
    };

    useEffect(() => {
        dispatch(actionsApi?.getChartapiDetail({ year: selectedYear, status: filters?.status }));
        setValue(filters?.status);
    }, [dispatch, selectedYear, filters]);

    const items = [
        {
            label: <a href="#">Monthly</a>,
            key: '0',
        },
        {
            label: <a href="#">Yealy</a>,
            key: '1',
        },
    ];

    const labels = addchartDetail?.map((label) => label.month);

    const data = {
        labels,
        datasets: [
            {
                label: 'Dataset 1',
                data: addchartDetail?.map((data) => data.count),
                backgroundColor: '#00BC70',
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'none',
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
            },
            y: {
                grid: {
                    display: false,
                },
            },
        },
    };

    return (
        <div>
            <Card className="radius-12 border0 shadow-c">
                <Row gutter={[24, 24]}>
                    <Col md={12}>
                        <ModuleTopHeading name="Patient’s Census" />
                    </Col>
                    <Col md={12}>
                        <Space align="baseline" className="w-100" size={10} style={{ justifyContent: 'end' }}>
                            <Dropdown
                                menu={{ items }}
                                trigger={['click']}
                                arrow
                            >
                                <Button className="margin-top">
                                    Monthly
                                    <DownOutlined />
                                </Button>
                            </Dropdown>
                        </Space>
                    </Col>
                    <Col span={24}>
                        <Radio.Group onChange={onChange} value={value}>
                            <Radio value={1}>Active patients</Radio>
                            <Radio value={2}>Inactive patients</Radio>
                        </Radio.Group>
                    </Col>
                    <Col span={24}>
                        <div>
                            <Bar options={options} data={data} />
                        </div>
                    </Col>
                </Row>
            </Card>
        </div>
    );
};

export { BarChartComponent };
