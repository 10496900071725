import React, { useEffect, useState } from 'react';
import { Button, Col, Dropdown, Flex, Form, Row, Table, Typography,Space } from 'antd';
import { ActionButton, ModuleTopHeading } from '../../../components/PageComponents';
import { MyInput } from '../../../components/Forms';
import { EyeOutlined, FilterOutlined } from '@ant-design/icons';
import { PrescriptionViewModal } from '../../Modals/PrescriptionViewModal';
import { actionsApi, TableLoader } from '../../../shared';
import { useDispatch, useSelector } from 'react-redux';

const { Text } = Typography

let timer;

const PatientPrescriptionTable = ({ serviceid }) => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const { addservicepatientDetail, loadingAddservicepatientDetail, filters, pagination } = useSelector(state => state?.servicepatient);
  const [order, setOrder] = useState(1);
  const [ medid, setMedId ] = useState(null)

  const items = [
    { label: <a href="#">A-Z</a>, key: '0' },
    { label: <a href="#">Z-A</a>, key: '1' },
  ];

  useEffect(() => {
    if (serviceid) {
      dispatch(actionsApi?.getAddServicepatientDetail({ ...filters, ...pagination, pageNo: 1, id: serviceid }));
      form.setFieldsValue({ ...filters });
      setOrder(filters?.order);
    }
  }, [serviceid]);

  const filter = (order) => {
    let data = form.getFieldsValue();
    dispatch(actionsApi?.getAddServicepatientDetail({ ...data, order, ...pagination, pageNo: 1, id: serviceid }));
    dispatch(actionsApi?.changeAddservicepatientDetailFilters({ ...data, order }));
  };

  const call = (pageNo = 1, pageSize = 10) => {
    dispatch(actionsApi?.getAddServicepatientDetail({ ...filters, pageNo, pageSize, id: serviceid }));
  };

  function searchHandler(name, pageNo = 1, pageSize = 20) {
    const newFilters = { ...filters, name, pageNo, pageSize, id: serviceid };
    dispatch(actionsApi?.getAddServicepatientDetail(newFilters));
    dispatch(actionsApi?.changeAddservicepatientDetailFilters(newFilters));
  }

  const onClick = ({ key }) => {
    key = parseInt(key) + 1;
    setOrder(key);
    filter(key);
  };

  function debounce(func, delay) {
    return function(...args) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }

  const debouncedSearchHandler = debounce(searchHandler, 400);

  const formatDateTime = (dateTime) => {
    const date = new Date(dateTime);
    return date.toLocaleString();
  };

  const columns = [
    {
      title: 'Assigned Doctor',
      dataIndex: 'doctor',
      key: 'doctor',
      render: doctor => `${doctor.firstName} ${doctor.lastName}`,
    },
    {
      title: 'Assigned Nurse',
      dataIndex: 'nurse',
      key: 'nurse',
      render: nurse => `${nurse.firstName} ${nurse.lastName}`,
    },
    {
      title: 'Patient Condition',
      dataIndex: 'patientConditions',
      key: 'patientConditions',
    },
    {
      title: 'Assigned Date',
      dataIndex: 'dateTime',
      key: 'dateTime',
      render: dateTime => formatDateTime(dateTime),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 140,
      render:(status)=>(
        <Space>
            <img src={status === 1 ? "/assets/icons/online.png" : "/assets/icons/offline.png"} width={'12px'} alt="" />
            <Text style={{ transform: "translateY(-2px)", display: 'block' }}>{status === 1 ? 'Active' : 'Inactive'}</Text>
        </Space>
      )
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      align: 'right',
      width: 100,
      render: (_, row) => (
        <ActionButton
          title='View Item'
          icon={<EyeOutlined />}
          onClick={() => { 
            setMedId({ id: row?.id, date: row?.dateTime })
            setVisible(true); 
          }}
          type='ghost'
        />
      ),
    },
  ];

  return (
    <Row gutter={[12, 12]}>
      <Col span={14}>
        <ModuleTopHeading name='Prescription' />
      </Col>
      <Col span={24}>
        <Form form={form} layout="vertical">
          <Flex gap={'small'} className='w-100' align='end'>
            <MyInput
              name='searchTerm'
              label='Search'
              placeholder='Search by name'
              value={form.getFieldValue("searchTerm") || ''}
              className='w-100'
              onChange={(e) => debouncedSearchHandler(e.target.value, 1, 20)}
            />
            <Dropdown
              menu={{ items, onClick }}
              trigger={['click']}
              arrow
              icon={<FilterOutlined />}
              className='margin-top'
            >
              <Button icon={<FilterOutlined />}>Filter</Button>
            </Dropdown>
          </Flex>
        </Form>
      </Col>
      <Col span={24}>
        <Table
          size='large'
          columns={columns}
          dataSource={addservicepatientDetail}
          scroll={{ x: 1000 }}
          pagination={{
            hideOnSinglePage: true,
            total: pagination?.totalRecords,
            pageSize: pagination?.pageSize,
            defaultPageSize: pagination?.pageSize,
            current: pagination?.pageNo,
            size: "default",
            pageSizeOptions: ['10', '20', '50', '100'],
            onChange: (pageNo, pageSize) => call(pageNo, pageSize),
            showTotal: (total) => <Button>Total: {total}</Button>,
          }}
          loading={{ ...TableLoader, spinning: loadingAddservicepatientDetail }}
        />
      </Col>
      <PrescriptionViewModal
        visible={visible}
        medid= {medid}
        onClose={() => {setVisible(false);setMedId(null)}}
      />
    </Row>
  );
};

export { PatientPrescriptionTable };
