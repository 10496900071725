import React from 'react'
import { PlannerComponents } from '../../components'

const Planner = () => {
  return (
    <div>
     <PlannerComponents />
    </div>
  )
}

export {Planner}