import React, { useState } from 'react'
import { Button, Col, Flex, Form, Image, Input, Row, Typography } from 'antd'
import { useNavigate } from 'react-router-dom'

const { Title, Text } = Typography

const SuccessScreen = () => {

    const [form] = Form.useForm()
    const navigate = useNavigate()

  return (
    <div className='login-form-cover' >
        <Flex justify="center">
            <Row className="login-form border-grey" justify={"center"}>
                <Col xs={24} sm={24} md={14}>
                    <Flex vertical align="center" gap={'large'} justify="center" className="height-100">
                        <Flex vertical align="center" justify="center">
                            <Image
                                src={"./assets/logo.png"}
                                alt='Store App Admin Panel'
                                preview= {false}
                                width={'150px'}
                            />
                            <Title 
                                level={5} 
                                className="text-center success-color fs-15"
                                >
                                Password updated
                            </Title>
                            <Text className="text-center fs-13">
                                Your password has been updated successfully
                            </Text>
                            <Flex className='my-3' justify='center' align='center' vertical gap={'small'}>
                                <Button 
                                    type="primary"
                                    htmlType="submit" 
                                    onClick={()=> navigate('/')}
                                    >
                                    Back to login
                                </Button>
                            </Flex>
                        </Flex>
                    </Flex>
                </Col>
            </Row>
        </Flex>
    </div>
  )
}

export {SuccessScreen}