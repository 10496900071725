import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl } from '../../../constants'
import { checkAuthorization } from '../../../helpers';
import { message } from 'antd'

export const getAddCardDetail = createAsyncThunk('getCardStatistics',
    async (_, { dispatch, getState }) => {

        const {userToken}= checkAuthorization()
        dispatch(gettingAddCardDetail())
        var myHeaders = new Headers();
        myHeaders.append("Authorization", userToken)
        myHeaders.append("Content-Type", "application/json")
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(domainUrl + `/medicalDirectorStats`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result?.success) {
                    dispatch(addcardDetailResponse(result))
                }
                else 
                    throw result?.message
            })
            .catch(error => { 
                dispatch(addcardDetailClear())
                message.error(error) 
            })
    }
)

const AddCardSlice = createSlice({
    name: 'addcard',
    initialState: {
        data: null,
        loading: false,
        cardDetail: null,
        loadingcardDetail: false,
    },
    reducers: {
        getPatientStatistics: state => {
            state.loading=true
            state.data=null
        },
        statisticsResponse: (state, action)=>{
            state.data= action.payload
            state.loading= false
        },
        statisticsClear: (state)=>{
            state.data= null
            state.loading= false
        },
        gettingAddCardDetail: state => {
            state.loadingcardDetail=true
            state.cardDetail=null
        },
        addcardDetailResponse: (state, action)=>{
            state.cardDetail= action.payload
            state.loadingcardDetail= false
        },
        addcardDetailClear: (state)=>{
            state.cardDetail= null
            state.loadingcardDetail= false
        },
        setPages:(state, action)=>{
            state.pagination= action.payload
        },
    },
})
export const { 
    gettingaddboxStatistics, statisticsResponse, statisticsClear,
    gettingAddCardDetail, addcardDetailResponse, addcardDetailClear,
    changeAddpatientDetailFilters, setPages
} = AddCardSlice.actions;
export default AddCardSlice.reducer;