import React, { useEffect, useState } from 'react';
import { Button, Col, Flex, Form, Image, Input, message, Row, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { apiCalls } from '../../../shared/Apis';

const { Title } = Typography;

const Otps = ({setOtpId}) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  
  const [counterstart, setCounterStart] = useState(120);

  const OtpVerify = async () => {
    setLoading(true);
    const formData = await form.validateFields();
    try {
      const result = await apiCalls?.forgotpasswordApi?.OtpVerify(formData);
      setLoading(false);
      if (result) {
        setOtpId(result?.id);
        form.resetFields();
        navigate('/newcredential');
      }
    } catch (error) {
      message.error('Error:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCounterStart((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `0${minutes}:${secs < 10 ? `0${secs}` : secs}`;
  };

  return (
    <div className='login-form-cover'>
      <Flex justify="center">
        <Row className="login-form border-grey" justify={"center"}>
          <Col xs={24} sm={24} md={14}>
            <Flex vertical align="center" gap={'large'} justify="center" className="height-100">
              <Flex vertical align="center" justify="center">
                <Image
                  src={"./assets/logo.png"}
                  alt='Store App Admin Panel'
                  preview={false}
                  width={'150px'}
                />
                <Title level={5} className="text-center brand-color fs-13">
                  Enter OTP (One time password) sent to user@email.com
                </Title>
              </Flex>
              <Form
                form={form}
                layout="vertical"
                initialValues={{ remember: true }}
                style={{ width: '100%' }}
                onFinish={OtpVerify}
              >
                <Row gutter={[16, 16]} justify={'center'}>
                  <Col xs={24} sm={24} md={12}>
                    <Flex vertical gap={3} align='center'> 
                      <Form.Item name='otp' className='m-0'>
                        <Input.OTP type='number' 
                          value={form.getFieldValue('otp') || ''} 
                          onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }} />
                      </Form.Item>
                      <Typography.Text className='fs-12 block' style={{ textAlign: "end" }}>{formatTime(counterstart)} minutes</Typography.Text>
                    </Flex>
                  </Col>
                  <Col span={24}>
                    <Flex justify='center' align='center' vertical gap={'small'}>
                      <Button type="primary" htmlType="submit" loading={loading}>
                        Verify Code
                      </Button>
                      <Button type="link" onClick={()=>navigate('/sendemail')} disabled={counterstart === 0 ? false : true} ghost className='brand-color' >
                        Resend Code
                      </Button>
                    </Flex>
                  </Col>
                </Row>
              </Form>
            </Flex>
          </Col>
        </Row>
      </Flex>
    </div>
  );
};

export { Otps };
