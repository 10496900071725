import React, { useEffect, useState } from 'react'
import { Image, Space, Row, Col, Form,Dropdown, Card, Table, Button, Flex, Typography } from 'antd'
import { MyInput, ModuleTopHeading, ActionButton, AddapointmentDrawer, AppointmentSendMsgModal, AlertAppointModal } from '../../components';
import { DeleteOutlined, EyeOutlined, FilterOutlined, ScheduleOutlined } from '@ant-design/icons';
import { RescheduleAppointmentModal } from '../../components/AppointmentsComponents/RescheduleAppointmentModal';
import { actionsApi, TableLoader } from '../../shared';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

let timer
const { Text } = Typography

const Appointment = () => {
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const [ visible, setVisible ] = useState(false)
    const { addappointmentDetail, loadingAddAppointmentDetail, filters, pagination } = useSelector(state => state?.appointment)
    const [ viewonly, setViewOnly ] = useState(false)
    const [ deletemodal, setDeleteModal ] = useState(false)
    const [ deleteappid, setDeleteAppId ] = useState(null)
    const [ reschedulmodal, setReschedulModal ] = useState(false)
    const [ visiblemessage, setVisibleMessage] = useState(false)
    const [ rescheduleid, setRescheduleId ] = useState(null)
    const [order, setOrder] = useState(1)

    const items = [
        {
            label: <a href="#">A-Z</a>,
            key: '0',
        },
        {
            label: <a href="#">Z-A</a>,
            key: '1',
        },
        {
            label: <a href="#">Most downloads</a>,
            key: '2',
        },
        {
            label: <a href="#">Less downloads</a>,
            key: '3',
        },
    ];

    useEffect(() => {
        dispatch(actionsApi?.getAddAppointmentDetail({ ...filters, ...pagination, pageNo: 1 }))
        form.setFieldsValue({
            ...filters,
        })
        setOrder(filters?.order)

    }, [])
    const filter = (order) => {
        let data = form.getFieldsValue()
        dispatch(actionsApi?.getAddAppointmentDetail({ ...data, order, ...pagination, pageNo: 1 }))
        dispatch(actionsApi?.changeAddappointmentDetailFilters({ ...data, order }))
    }
    const call = (pageNo = 1, pageSize = 10) => {
        dispatch(actionsApi?.getAddAppointmentDetail({ ...filters, pageNo, pageSize }))
    }

    function searchHandler(name, pageNo=1, pageSize=20) {
        dispatch(actionsApi?.getAddAppointmentDetail({ ...filters, name, pageNo, pageSize }))
    }

    const onClick = ({ key }) => {
        key = parseInt(key) + 1
        setOrder(key)
        filter(key)
    }
    function debounce(func, delay) {
        return function(...args) {
            clearTimeout(timer)
            timer = setTimeout(() => {
                func.apply(this, args)
            }, delay)
        };
    }
    const debouncedSearchHandler = debounce(searchHandler, 400)
  
  
    const columns = [
        {
            title: 'Patient name',
            dataIndex: 'patient',
            render: patient => `${patient.firstName } ${patient?.lastName}`

        },
        {
            title: 'Visit type',
            dataIndex: 'visitType',
        },
        {
            title: 'Location',
            dataIndex: 'location',
        },
        {
            title: 'Appointment date',
            dataIndex: 'appointment_dates',
            render: (appointment_dates) => (
                <div>
                    {appointment_dates?.map((item, index) => (
                        <Flex key={index} gap={5}>
                            <div>
                                {moment(item.date).format('MMM Do YYYY')}
                            </div>
                            -
                            <div>
                                {moment(item.fromTime, 'HH:mm:ss').format('h:mm A')} - {moment(item.toTime, 'HH:mm:ss').format('h:mm A')}
                            </div>
                        </Flex>
                    ))}
                </div>
            ),
            width: 300,
        },                                        
        {
            title: 'Created on',
            dataIndex: 'dateTime',
            render: (dateTime) => (
                <Flex gap={5}>
                    <div>{moment.utc(dateTime).local().format('MMMM Do YYYY')}</div>
                    -
                    <div>{moment.utc(dateTime).local().format('h:mm A')}</div>
                </Flex>
            ),
            width:250,
        },        
        {
            title: 'Status',
            dataIndex: 'status',
            render: (status) => {
                return (
                    status === 1 ? (
                        <Space align='center'>
                            <img src="/assets/icons/online.png" width={'12px'} alt="" />
                            <Text style={{transform:"translateY(-2px)",display:'block'}}>Active</Text>
                        </Space>
                    ) : (
                        <Space>
                            <img src="/assets/icons/offline.png" width={'12px'} alt="" />
                            <Text style={{transform:"translateY(-2px)",display:'block'}}>Inactive</Text>
                        </Space>
                    )
                );
            }
        },
        {
            title: 'Action',
            key: 'action',
            fixed: 'right',
            align:'right',
            width: 150,
            render: (_, row) => (
                <Space align='center'>
                    <ActionButton
                        title='View Item'
                        icon={<EyeOutlined />}
                        onClick={() => {setVisible(true);setViewOnly({id:row?.id})}}
                        type='ghost'

                    />
                    <ActionButton
                        title='Reschedule'
                        icon={<ScheduleOutlined />}
                        onClick={()=>{ setReschedulModal(true); setRescheduleId(row?.id) }}
                        type='ghost'

                    />
                    <ActionButton
                        title='Add Message'
                        icon={<Image src='/assets/icons/msg.png' width={16} preview={false} />}
                        onClick={() => { setVisibleMessage(true) }}
                        type='ghost'

                    />
                    <ActionButton
                        title='Delete item'
                        icon={<DeleteOutlined className='danger-color' />}
                        onClick={() => { setDeleteModal(true); setDeleteAppId({id: row?.id, name: row?.visitType})}}
                        type='ghost'
                        danger
                    />
                </Space>
            ),
        },
    ];

  
    return (
        <div>
            <Card className='radius-12 border0 shadow-c'>
                <Row gutter={[12,12]}>
                    <Col lg={14}>
                        <ModuleTopHeading
                            name='Appointment'
                            title='Add appointment'
                            onClick={()=>setVisible(true)}
                        />
                    </Col>
                    <Col  span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <Flex gap={'small'} className='w-100' align='end'>
                                <MyInput
                                    name='search'
                                    label='Search'
                                    placeholder='Search'
                                    onChange={(e)=> debouncedSearchHandler(e.target.value, 1, 20)} 
                                    className='w-100'
                                />
                                <Dropdown
                                    menu={{
                                        items,
                                        onClick
                                    }}
                                    trigger={['click']}
                                    arrow
                                    icon={<FilterOutlined />}
                                    className='margin-top'
                                >
                                    <Button
                                        icon={<FilterOutlined />}
                                    >
                                        Filter
                                    </Button>
                                </Dropdown>
                            </Flex>
                        </Form>
                    </Col>
                    <Col span={24}>
                        <Table 
                            size='large'
                            columns={columns} 
                            dataSource={addappointmentDetail}
                            scroll={{x: 1000,}}
                            pagination={{
                                hideOnSinglePage: true,
                                total: pagination?.totalRecords,
                                pageSize: pagination?.pageSize,
                                defaultPageSize: pagination?.pageSize,
                                current: pagination?.pageNo,
                                size: "default",
                                pageSizeOptions: ['10', '20', '50', '100'],
                                onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                                showTotal: (total) => <Button>Total: {total}</Button>,
                            }}
                            loading={
                                {
                                    ...TableLoader,
                                    spinning: loadingAddAppointmentDetail
                                }
                            }
                        />
                    </Col>
                </Row>
            </Card>
            <AddapointmentDrawer 
                visible={visible}
                viewonly={viewonly}
                getAddAppointmentDetail={call}
                onClose={()=>{setVisible(false);setViewOnly(null)}}
            />

            <RescheduleAppointmentModal 
                reschedulmodal={reschedulmodal}
                rescheduleid={rescheduleid}
                getAddAppointmentDetail={call}
                onClose={()=>{setReschedulModal(false);setRescheduleId(null)}}
            />

            <AppointmentSendMsgModal 
                visiblemessage={visiblemessage}
                onClose={()=>setVisibleMessage(false)}
            />
            <AlertAppointModal 
                deletemodal={deletemodal}
                deleteappid={deleteappid}
                getAddAppointmentDetail={call}
                onClose={()=>{setDeleteModal(false);setDeleteAppId(null)}}
            />
        </div>

    )
}

export {Appointment}