import React, { useEffect, useState } from 'react'
import { Card, Col, Row, Typography,Space, Image, theme, Skeleton } from 'antd'
import { useDispatch, useSelector } from 'react-redux';
import { actionsApi } from '../../shared';

const { useToken } = theme;
const { Title, Text } = Typography
const CardComponent = () => {
    
    const { token } = useToken();
    const dispatch = useDispatch()
    const { cardDetail, loadingcardDetail } = useSelector(state => state?.cardapi)
    const [state, setState]= useState(null)

    useEffect(() => {
        dispatch(actionsApi?.getAddCardDetail())
    }, [])


    useEffect(()=>{
        if(cardDetail)
        {
            setState([
                {
                    title:cardDetail?.totalPatients,
                    subtitle:'Total Patients',
                    img:'totalpat.png',
                },
                {
                    title:cardDetail?.totalAppointments,
                    subtitle:'Total Appointments',
                    img:'inactive-appoint.png',
                },
                {
                    title:cardDetail?.totalVisits,
                    subtitle:'Total Visits',
                    img:'totalvisit.png',
                },
            ])
        }
        else 
        setState(null)
    }, [cardDetail])

  return (
    <div>
        <Row gutter={[24,24]}>
            {
                loadingcardDetail ?
                new Array(3)?.fill(null)?.map((_, index)=>
                    <Col 
                        xs={24} sm={24} md={12} lg={12} xl={6} 
                        key={'skelton-card-' + index}
                    >
                        <Skeleton.Button 
                            active 
                            size='large' 
                            shape='square'
                            block
                            style={{width:'100%', height:'114px',borderRadius:20}}
                        />
                    </Col>
                )
                :
                state?.map((cdata, index)=>
                    <Col 
                        xs={24} sm={24} md={12} lg={12} xl={6}
                        key={'stat-card-' + index}
                    >
                        <Card style={{borderColor: token.borderCardcolor,borderRadius: token.borderCardrad,boxShadow: token.cardshadow,width:"100%"}}>
                            <Space size={80} style={{justifyContent:"space-between"}} className='w-100'>
                                <div>
                                    <Text className='text-input' strong>{cdata?.subtitle}</Text>
                                    <Title level={3} className='text-blue font-sans my-2'>{cdata?.title}</Title>
                                </div>
                                <Image src={'/assets/icons/'+cdata?.img} width={'34px'} />
                            </Space>
                        </Card>
                    </Col>
                )
            }
        </Row>
    </div>
  )
}

export {CardComponent}