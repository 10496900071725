
//login
import { login, loginResponse, loginClear, AuthCheck, refreshToken } from "./action/Login"
//dashboard
import { getAddPatientDetail, changeAddpatientDetailFilters } from "./action/addpatient"
import { getAddEmployeeDetail, changeAddemployeeDetailFilters } from "./action/employee"
import { getAddAppointmentDetail, changeAddappointmentDetailFilters } from "./action/appointment"
import { getAddCardDetail } from "./action/cardapi"
import { getChartapiDetail, changeAddChartDetailFilters } from "./action/chartapi"
import { getAddDashboardDetail, changeAdddashboardDetailFilters } from "./action/dashboard"
import { getAddServicepatientDetail, changeAddservicepatientDetailFilters } from "./action/servicepatient"
import { getViewMedpresDetail } from "./action/viewmedicineprescription"
import { getVisitDetail, changeVisitViewDetailFilters } from './action/visitapi'
import { getVisitSingleDetail } from './action/visitapisingle'


export const actionsApi = {
    login, 
    loginResponse, 
    loginClear, 
    AuthCheck, 
    refreshToken,
    //addpatient 
    getAddPatientDetail, 
    changeAddpatientDetailFilters,
    // addemployee
    getAddEmployeeDetail,
    changeAddemployeeDetailFilters,
    // addappointment
    getAddAppointmentDetail,
    changeAddappointmentDetailFilters,
    // dashboard
    getAddCardDetail,
    getChartapiDetail,
    changeAddChartDetailFilters,
    getAddDashboardDetail,
    changeAdddashboardDetailFilters,

    // service patient by id
    getAddServicepatientDetail,
    changeAddservicepatientDetailFilters,
    getViewMedpresDetail,
    // visit api in service drawer
    getVisitDetail, 
    changeVisitViewDetailFilters,
    getVisitSingleDetail,
}
