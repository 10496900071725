import { message } from "antd";
import { checkAuthorization } from "../../helpers";
import { domainUrl } from "../../constants";


const changePasswordPost = (formData) => {
    const {userToken} = checkAuthorization();
    var myHeaders = new Headers();
    myHeaders.append(
        "Content-Type", "application/json",
    );
    myHeaders.append('Authorization', userToken);
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(formData),
        redirect: 'follow'
    };
    return(
        fetch(domainUrl + '/changePassword', requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result?.success) {
                message.success(result?.message);
                return 1
            } 
            else 
                throw result?.message
        })
        .catch(error => {
            message.error(error.message || 'Something went wrong');
        })
    )
};

const changepassApi = {
    changePasswordPost
}

export { changepassApi }