import React, { useEffect } from 'react'
import { Divider, Flex, Modal, Typography } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { actionsApi } from '../../../shared'
import moment from 'moment'

const { Text } = Typography

const VisitChaplinModal = ({visible,onClose,getvisitid}) => {


    const dispatch = useDispatch()
    const { visitsingDetail } = useSelector(state => state?.visitapisingle)

    useEffect(() => {
        if(getvisitid){
        dispatch(actionsApi?.getVisitSingleDetail({ id: getvisitid?.id}))
        }
    }, [getvisitid])

    const datas = {
        title:"Instructions - by Admin",
        desc:"Patient Anna Ken, experiencing emotional distress, has expressed a preference for mental health support from a chaplain. This request underscores her need for holistic and spiritual care, emphasizing the importance of addressing her emotional well-being through personalized and compassionate services.",
        lastBlock:[
            {
                title:"Details by chaplain:",
                desc:"Provide Spirtual, emotional and mental support",
                date: <Typography.Text> <strong>Time spent</strong>: 1 hour</Typography.Text>,
            },
            {
                title:"Remarks:",
                desc:"Lorem ipsum"
            },
            {
                title:"Remarks:",
                desc:"Lorem ipsum"
            },
        ]
      }
  
    return (
        <Modal
          title={visible ? '#'+getvisitid.id.substr(0,5) + ' - ' + getvisitid?.name + ' - ' + moment(getvisitid?.date).format('MMM Do YYYY') :''}
          centered
          open={visible}
          onOk={onClose}
          onCancel={onClose}
          width={1000}
          footer={false}
        >
          <Flex gap={'small'} className='pb-2 pt-3' justify='space-between'>
            <Text strong>{datas?.title}</Text>
          </Flex>
          <Text>
            {
                visitsingDetail?.instruction
            }
          </Text>
          <Divider />
            <Flex justify='space-between'>
                <Text>
                    <strong>Details by {visitsingDetail?.role}</strong>
                    <ul className='py-2'>
                        {
                            visitsingDetail?.services?.map((data,index)=>
                                <li key={index}>
                                    {data}
                                </li>
                            )
                        }
                    </ul>
                </Text>
                <Flex gap={5}>
                    <Text strong>Time spent: </Text>
                    <Text> {visitsingDetail?.timeSpend}</Text>
                </Flex>
            </Flex>
            <Flex className='pt-3' vertical gap={4}>
                <Text strong>Patient condition</Text>
                <Text>{visitsingDetail?.patientCondition}</Text>
            </Flex>
            <Flex className='pt-3' vertical gap={4}>
                <Text strong>Comments</Text>
                <Text>{visitsingDetail?.comments}</Text>
            </Flex>
        </Modal>
    )
}

export {VisitChaplinModal}