import React from 'react'
import { Button, Col, Flex, Form, Image, Input, Modal, Row, Typography } from 'antd'

const { Title, Text } = Typography

const ViewPatientDetailModal = ({visiblemodal,onClose,eventid}) => {
    const [form] = Form.useForm()

    const data = [
        {
            title:'Visit type',
            desc:`${eventid?.visitType}`
        },
        {
            title:'Appointment Date',
            desc:`${eventid?.AppointmentDate}`
        },
        {
            title:'Location',
            desc:`${eventid?.location}`
        },
        {
            title:'Instruction',
            desc:`${eventid?.instruction}`,
            key:'instruct'
        },
    ]

  return (
    <Modal
          centered
          open={visiblemodal}
          onOk={onClose}
          onCancel={onClose}
          footer={false}
        >
            <Flex gap={'large'} vertical>
                <Flex vertical align='center'>
                    <Title level={3} className='m-0 text-capitalize'>{eventid?.firstName + ' ' + eventid?.lastName}</Title>
                    <Text>{'#'+eventid?.id.substr(0,5)}</Text>
                </Flex>
                {
                    data?.map((data,index)=>
                        <Flex 
                            justify='space-between' key={index}
                            vertical={data?.key==='instruct'? true:false}
                            gap={data?.key==='instruct'? 10:''}
                        >
                            <Text strong>
                                {
                                    data?.title
                                }
                            </Text>
                            <Text className='justify'>
                                {
                                    data?.desc
                                }
                            </Text>
                        </Flex>
                    )
                }
            </Flex>
    </Modal>
  )
}

export {ViewPatientDetailModal}